import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { FRONTEND_DASHBOARD} from '../../settings';
import { CenterBelowLogo } from '../util/Rendering';
import Logo from "../dashboard/sub/Logo";

import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { checkInputs } from '../util/Checks';
import { InputElement, Button, Tab, Form } from '../util/Form';
import { Mode } from './sub/Modes';
import { backendPostRequest } from '../util/BackendRequest';

import { BACKEND_LOGIN, BACKEND_REGISTER_RESEND_EMAIL, BACKEND_RESET_PASSWORD, ERROR_UNABLE_TO_LOG_IN, MESSAGE_ACCOUNT_UNCONFIRMED, MESSAGE_FORGOT_PASSWORD_EMAIL_SENT } from "../../settings";
import { toast } from 'react-toastify';

const Login = ({setMode}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onLogin = e => {
        if (!checkInputs(["emailInput", "passwordInput"])) return;

        const myErrorParser = (res) => {
            if (res.key) return null;
            if (res.non_field_errors) return res.non_field_errors[0];
            if (res.error) return res.error;
            return ERROR_UNABLE_TO_LOG_IN;
        }

        const myErrorHandler = (myError) => {
            setPassword('');
            if (myError && myError.includes('not verified')) {
                backendPostRequest(
                BACKEND_REGISTER_RESEND_EMAIL, 
                false, JSON.stringify({email: email}))
                .then(data => {
                if (!data) return;
                    toast.success(MESSAGE_ACCOUNT_UNCONFIRMED);
                });
            }
        }

        backendPostRequest(
            BACKEND_LOGIN, false, 
            JSON.stringify({email: email, password: password}),
            myErrorParser, myErrorHandler)
        .then(data => {
            if (!data) return;
            localStorage.setItem('token', data.key);
            setMode(Mode.SIGNUP);
        })
    };

    const onReset = e => {
        if (!checkInputs(["emailInput"])) return;
        setPassword('');
        backendPostRequest(BACKEND_RESET_PASSWORD, 
                       false, JSON.stringify({email: email}))
        .then(res => {
            if (!res) return;
            toast.success(MESSAGE_FORGOT_PASSWORD_EMAIL_SENT);
        })
    };

    const onSignup = () => {
        setMode(Mode.SIGNUP);
    }

    if (localStorage.getItem('token') !== null)
        return <Redirect to={FRONTEND_DASHBOARD}/>;

    return (
        <>
        <Logo className="logo-corner"/>
        <CenterBelowLogo>
        <Form
            tabs={[
                <Tab key="Log In"
                    label="Log In" rightMargin="margin-right-s" />,
                <Tab key="Sign Up"
                     label="Sign Up" 
                     onClick={onSignup} />]}

            inputs={[
                <InputElement key="emailInput"
                    id="emailInput" label="Email address:" type="email"
                    hook={email} onChange={setEmail} />,
                <InputElement key="passwordInput"
                    id="passwordInput" label="Password:" type="password"
                    hook={password} onChange={setPassword} />]}

            buttons={[
                <Button key="loginButton" className="submit"
                    id="loginButton" icon={faSignInAlt} 
                    handler={onLogin} />,
                <Button key="forgotButton"
                    id="forgotButton" icon="Forgot password" 
                    handler={onReset} />
            ]}
        />
        </CenterBelowLogo>
        </>
    );
};

export default Login;