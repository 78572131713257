import React, { useState } from 'react';
import { translate } from '../util/Dictionary';
import Back from './sub/CourseCard/Back';
import Face from './sub/CourseCard/Face';
import Mode from './sub/CourseCard/Modes';

const CourseCard = ({course, dictionary,
                    setCourse, closeMode, onPreview, onLearn, setSub}) => {
  const [mode, setMode] = useState(Mode.FEATURES_AND_PRICE);
  const [modeStack, setModeStack] = useState([]);

  let doNotFlip = false;
  const enableFlip = () => {doNotFlip = false;}
  const disableFlip = () => {doNotFlip = true;}
  
  const onClose = () => {
    setCourse(null);
    closeMode();
  }

  const onFlip = () => {
    if (doNotFlip) {
      enableFlip();
      return;
    }
    let face=document.getElementById('face');
    let back=document.getElementById('back');
    face.classList.toggle('face-flipped');
    back.classList.toggle('back-flipped');
  }

  const pushMode = (newMode) => {
    setModeStack(s => [...s, mode]);
    setMode(newMode);
  }

  const popMode = () => {
    const lastIndex = modeStack.length - 1;
    setMode(modeStack[lastIndex]);
    const newModeStack = [...modeStack];
    newModeStack.splice(lastIndex, 1);
    setModeStack(newModeStack);
    disableFlip();
  }

  const onPurchase = () => {
    pushMode(Mode.REFUND_POLICY);
    disableFlip();
  }

  const onFinancialAid = () => {
    pushMode(Mode.FINANCIAL_AID);
    disableFlip();
  }

  const onAgree = () => {
    pushMode(Mode.PAYPAL);
    disableFlip();
  }

  const heightHeuristic = () => {
    const faceDescriptionLines = Math.ceil(course.description.length / 50);
    const faceOtherLines = 9;
    const faceHeight =
      (faceDescriptionLines + faceOtherLines) * 2.1 +
      1.1 * Math.ceil(40 * 9 / 16);

    let backFeatureLines = 0
    for (const feature of translate(course.features, dictionary)[0])
      backFeatureLines += Math.ceil(feature[1].length / 20);
    const backOtherLines = 7;
    const backHeight = (backFeatureLines + backOtherLines) * 2.1;

    //console.log(faceHeight, backHeight);
    return Math.max(faceHeight, backHeight);
  }

  if (!course) return '';
  const heightStyle = {height: `min(${heightHeuristic()}em, 100%)`}
  let [faceClass, backClass] = ["card-face", "card-back"];
  if (course.language === 'Hebrew') {
    faceClass += " rtl"; backClass += " rtl";
  }
  return (
    <div 
      style = {heightStyle}
      className = {"card" + (course.language==='Hebrew' ? " rtl" : '')}
      onClick={() => onFlip()}>
      
      <Face
        course={course} 
        faceClass={faceClass} 
        heightStyle={heightStyle}
        onClose={onClose} />
      
      <Back
        course={course} 
        backClass={backClass} 
        heightStyle={heightStyle}
        onPreview={onPreview}
        onLearn={onLearn}
        onPurchase={onPurchase}
        setSub={setSub}
        onFinancialAid={onFinancialAid}
        onAgree={onAgree}
        popMode={popMode}
        onClose={onClose}
        mode={mode}
        dictionary={dictionary}
        disableFlip={disableFlip} />
    </div>
  );
}

export default CourseCard;