import FRONTEND_ME_LOGO_IMAGE from "./img/me-logo.jpeg"

const getBackendUrl = () => {
    switch(window.location.hostname) {
        case 'localhost': return 'http://127.0.0.1:8000';
        case 'drmeir.co': return 'https://drmeir.pythonanywhere.com';
        case 'backend-staging.drmeir.co': 
            return 'https://staging-drmeir.pythonanywhere.com';
        default: return null;
    }
}

export const BACKEND_URL = getBackendUrl();

export const BACKEND_REGISTER = '/api/v1/users/auth/register/'
export const BACKEND_REGISTER_CONFIRM_EMAIL = '/api/v1/users/auth/register/confirm-email/'
export const BACKEND_REGISTER_RESEND_EMAIL = '/api/v1/users/auth/register/resend-email/'
export const BACKEND_LOGIN = '/api/v1/users/auth/login/'
export const BACKEND_RESET_PASSWORD = '/api/v1/users/auth/reset-password/'
export const BACKEND_RESET_PASSWORD_CONFIRM = '/api/v1/users/auth/reset-password/confirm/'
export const BACKEND_CHANGE_PASSWORD = '/api/v1/users/auth/change-password/'
export const BACKEND_LOGOUT = '/api/v1/users/auth/logout/'
export const BACKEND_USER = '/api/v1/users/auth/user/'
export const BACKEND_COURSES = '/content/courses/'
export const BACKEND_UNITS = '/content/units/'
export const BACKEND_CONTENT = '/content/content/'
export const BACKEND_COUPONS_LIST = '/content/coupons/list/'
export const BACKEND_COUPONS_ADD = '/content/coupons/add/'
export const BACKEND_KEYS_ADD = '/content/keys/add/'
export const BACKEND_CURRENT_UPDATE = '/content/current/update/'
export const BACKEND_DONE_UPDATE = '/content/done/update/'

export const BACKEND_ORDERS_CREATE = '/orders/create/'
export const BACKEND_ORDERS_CAPTURE = '/orders/capture/'
export const BACKEND_ORDERS_GET = '/orders/order/'

export const BACKEND_FINANCIAL_AID = '/orders/financial_aid/'

export const FRONTEND_SIGNUP = '/auth/signup'
export const FRONTEND_LOGIN = '/auth/login'
export const FRONTEND_DASHBOARD = '/dashboard'

export {FRONTEND_ME_LOGO_IMAGE};

export const MESSAGE_FORGOT_PASSWORD_EMAIL_SENT = 'You can set a new password by following the link in the email we just sent!';
export const MESSAGE_SIGNUP_CONFIRMATION_EMAIL_SENT = 'Please activate your account by following the link in the confirmation email that we sent!';
export const MESSAGE_ACCOUNT_UNCONFIRMED = 'Please activate your account by following the link in the new confirmation email we just sent!';
export const ERROR_UNABLE_TO_LOG_IN = 'Unable to log in with provided credentials.';
export const ERROR_PASSWORD_LENGTH = "The password must be at least 6 characters long.";
export const ERROR_PASSWORDS_NOT_MATCHING = "The two passwords must match.";
export const ERROR_NO_SERVER = <div>Oops, could not connect to the server! <br />Could be a network error...:(</div>;

// ------------------ Refund policy ---------------------------------

const EARLY_PRESALE_REFUND_POLICY = [
    "Refunds within seven days from purchase.",
    "Course availability: at least six months.",
    "[Detailed terms and conditions](https://github.com/DrMeir-co/terms/blob/main/README.md).", 
    ]

const PRESALE_REFUND_POLICY = [
    "Refunds within seven days from purchase.",
    "Course availability: at least six months.",
    "[Detailed terms and conditions](https://github.com/DrMeir-co/terms/blob/main/README.md).", 
    ]

const REGULAR_REFUND_POLICY = [
    "Refunds within seven days from purchase.",
    "Course availability: at least six months.",
    "[Detailed terms and conditions](https://github.com/DrMeir-co/terms/blob/main/README.md).", 
    ]

const REGULAR_REFUND_POLICY_HEBREW = [
    "אם הקורס לא יעמוד בציפיות שלכם, תוכלו לבקש החזר מלא.",
    "יש לבקש את ההחזר תוך *שבעה ימים* מהרכישה המקורית.",
    "לאחר מכן, הרכישה היא סופית."]

export const REFUND_POLICY = {
    "early presale": EARLY_PRESALE_REFUND_POLICY,
    "presale": PRESALE_REFUND_POLICY,
    "": REGULAR_REFUND_POLICY
}

export const REFUND_POLICY_HEBREW = {
    "": REGULAR_REFUND_POLICY_HEBREW
}

// ----------------------- Financial aid ---------------------------

export const INPUT_TYPES = {
    TEXTAREA: 0, SLIDER: 1
}

const FINANCIAL_AID_TITLE = "Application for financial aid."
const FINANCIAL_AID_INTRO = ["The price of this course reflects my commitment to the quality of the materials and to each student.", "I am happy to offer financial aid to people who truly (a) need this course and (b) cannot afford it."]
const FINANCIAL_AID_QUESTIONS = [["Why *this* course? There are cheaper ones out there.", INPUT_TYPES.TEXTAREA], ["Why can you not wait until you can afford the price?", INPUT_TYPES.TEXTAREA], ["What price can you afford to pay *today*?", INPUT_TYPES.SLIDER]]
const FINANCIAL_AID_ONLY_ONCE = "Note: you can apply for aid only once per course."
const FINANCIAL_AID_SUBMIT = "Submit application"
const FINANCIAL_AID_SUBMITTED = "Your application has been submitted. We aim to reply within 72 hours."

const FINANCIAL_AID_TITLE_HEBREW = "בקשה לסיוע כספי."
const FINANCIAL_AID_INTRO_HEBREW = ["מחיר הקורס משקף את מחויבותי לאיכות החומרים ולכל תלמיד.", "אני שמח להציע סיוע כספי לאנשים שבאמת (א) זקוקים לקורס הזה ו-(ב) ידם אינה משיגה לרכוש אותו."]
const FINANCIAL_AID_QUESTIONS_HEBREW = [["מדוע אתם רוצים לרכוש דווקא את הקורס הזה? ייתכן שישנם קורסים זולים יותר.", INPUT_TYPES.TEXTAREA], ["מדוע אינכם יכולים לדחות את הרכישה עד שתוכלו לעמוד במחיר?", INPUT_TYPES.TEXTAREA], ["איזה מחיר אתם יכולים להרשות לעצמכם לשלם *היום*?", INPUT_TYPES.SLIDER]]
const FINANCIAL_AID_ONLY_ONCE_HEBREW = "הערה: ניתן להגיש בקשה לסיוע רק פעם אחת בכל קורס."
const FINANCIAL_AID_SUBMIT_HEBREW = "הגש בקשה"
const FINANCIAL_AID_SUBMITTED_HEBREW = "הבקשה שלך הוגשה. אנו שואפים להשיב תוך שלושה ימי עסקים."

export const FINANCIAL_AID = {
    FINANCIAL_AID_TITLE: FINANCIAL_AID_TITLE,
    FINANCIAL_AID_INTRO: FINANCIAL_AID_INTRO,
    FINANCIAL_AID_QUESTIONS: FINANCIAL_AID_QUESTIONS,
    FINANCIAL_AID_ONLY_ONCE: FINANCIAL_AID_ONLY_ONCE,
    FINANCIAL_AID_SUBMIT: FINANCIAL_AID_SUBMIT,
    FINANCIAL_AID_SUBMITTED: FINANCIAL_AID_SUBMITTED
}

export const FINANCIAL_AID_HEBREW = {
    FINANCIAL_AID_TITLE: FINANCIAL_AID_TITLE_HEBREW,
    FINANCIAL_AID_INTRO: FINANCIAL_AID_INTRO_HEBREW,
    FINANCIAL_AID_QUESTIONS: FINANCIAL_AID_QUESTIONS_HEBREW,
    FINANCIAL_AID_ONLY_ONCE: FINANCIAL_AID_ONLY_ONCE_HEBREW,
    FINANCIAL_AID_SUBMIT: FINANCIAL_AID_SUBMIT_HEBREW,
    FINANCIAL_AID_SUBMITTED: FINANCIAL_AID_SUBMITTED_HEBREW
}
