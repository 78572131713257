import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import { priceStr } from '../CourseCard/util';

const InvoiceTableRow = ({course, order}) => {
    const total = order.price;
    const tax = order.tax;
    const subtotal = (100 - tax) * total / 100;
    const currency = order.currency;

    const borderColor = '#90e5fc';
    const styles = StyleSheet.create({
        row: {
            flexDirection: 'row',
            borderBottomColor: '#bff0fd',
            borderBottomWidth: 1,
            alignItems: 'center',
            height: 48,
            //fontStyle: 'bold',
        },
        leftcell: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            fontSize: 15,
            textAlign: 'left',
            paddingLeft: 6
        },
        rightcell: {
            borderRightColor: borderColor,
            borderRightWidth: 1,
            fontSize: 15,
            textAlign: 'right',
            paddingRight: 6
        },
        item: {width: '35%'},
        unit: {width: '14%'},
        qty: {width: '9%'},
        sub: {width: '14%'},
        tax: {width: '14%'},
        total: {width: '14%'},
        hebrew: {fontFamily: 'Rubik', textAlign: 'right', paddingRight: 6}
    });
    const languageStyle = (style) => {
        return course.language === 'Hebrew' ? 
            style.concat(styles.hebrew) : style;
    }
    const courseTitle = () => {
        return course.language === 'Hebrew' ? 
            'קורס ' + course.title : course.title + ' course';
    }

    return (
    <View style={styles.row}>
        <Text style={languageStyle([styles.leftcell, styles.item])}>
            {courseTitle()}
        </Text>
        <Text style={languageStyle([styles.rightcell, styles.unit])}>
            {priceStr(subtotal, currency)}
        </Text>
        <Text style={languageStyle([styles.rightcell, styles.qty])}>1.00</Text>
        <Text style={languageStyle([styles.rightcell, styles.sub])}>
            {priceStr(subtotal, currency)}
        </Text>
        <Text style={languageStyle([styles.rightcell, styles.tax])}>
            {priceStr(total - subtotal, currency)}
        </Text>
        <Text style={languageStyle([styles.rightcell, styles.total])}>
            {priceStr(total, currency)}
        </Text>
    </View>);
};
  
  export default InvoiceTableRow