import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';

import { BACKEND_REGISTER, FRONTEND_DASHBOARD, MESSAGE_SIGNUP_CONFIRMATION_EMAIL_SENT } from '../../settings';
import { CenterBelowLogo } from '../util/Rendering';
import Logo from "../dashboard/sub/Logo";

import { faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { checkInputs, clearCustomErrors } from '../util/Checks';
import { InputElement, Button, Tab, Form } from '../util/Form';
import { Mode } from './sub/Modes';
import { backendPostRequest } from '../util/BackendRequest';
import { toast } from 'react-toastify';

const Signup = ({setMode}) => {
    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');

    useEffect(() => {
        clearCustomErrors(["passwordInput1", "passwordInput2"]);     
    }, [email, firstname, password1, password2]);

    const onSignup = e => {
        if (!checkInputs([
            "emailInput", "firstnameInput", 
            "passwordInput1", "passwordInput2"]))
            return;

        if (email.split("@")[1] === 'drmeir.co') {
            toast.error('You cannot use drmeir.co as your email domain.');
            return;
        }

        const myErrorParser = (res) => {
            if (res.detail) return null;
            if (res.email) return res.email[0];
            if (res.error) return res.error;
            return 'An error occurred during signing up.';
        }

        toast.info("This may take a few moments...")
        backendPostRequest(
            BACKEND_REGISTER, false, 
            JSON.stringify({
                email: email,
                first_name: firstname,
                password1: password1,
                password2: password2}), myErrorParser)
            .then(data => {
            if (!data) return;
            toast.success(MESSAGE_SIGNUP_CONFIRMATION_EMAIL_SENT);
            setMode(Mode.LOGIN);
        });

        setPassword1(''); setPassword2('');
    };

    const onLogin = () => {
        setMode(Mode.LOGIN);
    }

    if (localStorage.getItem('token') !== null)
        return <Redirect to={FRONTEND_DASHBOARD}/>;

    return (
        <>
        <Logo className="logo-corner"/>
        <CenterBelowLogo>
        <Form
            tabs={[
                <Tab key="Log In"
                    label="Log In" 
                    onClick={onLogin}
                    rightMargin="margin-right-s" />,
                <Tab key="Sign Up"
                    label="Sign Up" />]}

            inputs={[
                <InputElement key="emailInput"
                    id="emailInput" label="Email address:" type="email"
                    hook={email} onChange={setEmail} />,
                <InputElement key="firstnameInput"
                    id="firstnameInput" label="First name:" type="text"
                    hook={firstname} onChange={setFirstname} />,
                <InputElement key="passwordInput1"
                    id="passwordInput1" label="Password:" type="password"
                    hook={password1} onChange={setPassword1}
                    commonWords={[
                        firstname, 
                        email.substring(0, email.indexOf("@"))]} />,
                <InputElement key="passwordInput2"
                    id="passwordInput2" label="Confirm password:" type="password"
                    hook={password2} onChange={setPassword2} />]}

            buttons={[
                <Button key="signupButton" className="submit"
                    id="signupButton" icon={faDoorOpen} 
                    handler={onSignup} />]}
        />
        </CenterBelowLogo>
        </>
    );
};

export default Signup;