import React from 'react';
import SmallCard from './sub/SmallCard';

const SmallCards = ({courses, setCourse, setMode}) => {
  if (!courses.length)
    return (
      <div className = "please-add-access font-indie-flower">
        Please add an <b>access code</b><br /> to get access to a course...
      </div>
    )
  return (
    <div className = "courses">
        {courses.map(
            c => <SmallCard key={c.title} 
                    course={c} 
                    setCourse={setCourse}
                    setMode={setMode}/>)}
    </div>
  );
}

export default SmallCards;
