// Repeatedly translate values in raw using dictionary
// until no more translations are possible.
// Currently only simple strings and nested arrays are handled.
// A value needs translation if it begins with underscore.
// The underscore is not part of the key to dictionary.
// Returns translated raw and a boolean showing
// whether translations were made and false otherwise.
export const translate = (raw, dictionary) => { 
    let change = true; // for one iteration
    let res = false; // global change
    while (change) {
        change = false;
        if (typeof(raw)==='string') {
            if (raw[0] === '_') {
                raw = dictionary[raw.substring(1)]
                change = true;
            }
        }
        else {
            for (let i=0; i<raw.length; ++i) {
                const [translation, myChange] = translate(raw[i], dictionary);
                raw[i] = translation;
                change = change || myChange;
            }
        }
        res = res || change;
    }
    return [raw, res];
}
/*
console.log(translate(['_aa', ['_bb', '_cc']], 
{'aa': 'zz', 'bb': 'yy', 'cc': ['_aa', '_bb']})[0]);
*/