import React from "react";
// import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Mode } from "./Modes";

const Dropdown = ({setMode, pushMode}) => {
  const onCoupons = () => {pushMode(Mode.COUPONS);}
  const onKeys = () => {pushMode(Mode.KEYS);}
  const onReceipts = () => {pushMode(Mode.RECEIPTS);}
  const onChangePassword = () => {pushMode(Mode.CHANGE_PASSWORD);}
  const onLogout = () => {setMode(Mode.LOGOUT);}
  
  return (
    <div className="user-and-dropdown">
      <div className="dashboard-nav-dropdown">
        <div className="dashboard-nav-bars">
          <FontAwesomeIcon icon={faBars} />
        </div>
          
        <div className="dashboard-nav-dropdown-items">
          {/*<div style={{visibility:'hidden', lineHeight: '1.5rem'}}>Hidden</div>*/}
          <div onClick={onCoupons}>Promo codes</div>
          <div onClick={onKeys}>Institutions</div>
          <div onClick={onReceipts}>Receipts & refunds</div>
          <div onClick={onChangePassword}>Change password</div>
          <div onClick={onLogout}>Log out</div>
        </div>
      </div>
    </div>);
}

export default Dropdown;
