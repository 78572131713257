import React from "react";

import meLogo from "../../../img/me-logo.jpeg"

const Logo = (props) => (
  <div className={props.className}>
    <div className={"col-me-logo " + (props.animations ? "animation-fade-in-4s" : "")}>
      <img src={meLogo} alt="Logo"/>
    </div>
    <div className="col-title">
      <div className="font-merienda-bold">
        <span className={"title-main " + (props.animations ? "animation-from-left" : "")}>DrMeir</span>
        <span className={"title-secondary " + (props.animations ? "animation-from-right" : "")}>Explained well. Explained for you.</span>
      </div>
    </div>
  </div>
);

// Simply insert it somewhere and snapshot.
export const Favicon = () => (
  <div style={{
    display:'flex', 
    justifyContent:'center', 
    alignItems:'end', 
    height:'18rem', 
    width:'18rem', 
    border:'1px solid black'}} className="font-indie-flower">
    
    <div className="title-main" style={{letterSpacing: '1px', margin:0}}>
      DM
    </div>
  </div>
);

export default Logo;
