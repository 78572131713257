import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons';
import { REFUND_POLICY, REFUND_POLICY_HEBREW } from '../../../../settings';
import { translate } from '../../../util/Dictionary';
import Payment from '../Payment';
import Mode from './Modes';
import { actualPriceStr, iconSrc, priceStr } from './util';
import FinancialAid from './FinancialAid';

const Features = ({ features, dictionary, groupSize = 1 }) => {
    return (
        features &&
        <ul className="card-features">
            {translate(features, dictionary)[0].map(
                (feature, i) => {
                    const containerClass =
                        (Math.floor(i / groupSize) % Math.max(groupSize, 2) === 0) ?
                            "card-feature-container-left" :
                            "card-feature-container-right";
                    const topMargin =
                        (i % groupSize === 0 && i !== 0) ?
                            <div className="card-feature-group-top-margin" /> :
                            '';

                    return (
                        <div key={feature[1]}>
                            {topMargin}
                            <li className={containerClass} key={"face:" + feature}>
                                <img className="card-feature-image" alt="course feature" 
                                src={iconSrc(feature[0])} />
                                <ReactMarkdown className="card-feature">
                                    {feature[1]}
                                </ReactMarkdown>
                            </li>
                        </div>
                    )
                })}
        </ul>);
}

const Price = ({ course, mode, price, currency, stage, projected, token, discount, onFinancialAid }) => {
    const myDict = {
        English: {
            priceText: (
                <div className="price-text">
                    {/*
                    The {' '}
                        <div style={{ display: 'inline-block'}}>
                            {stage}
                        </div> price:
                    */}
                    Price:
                </div>
            ),
            yourPrice: "Your price",
            promoCode: "Promo code",
            discount: "Discount",
            financialAid: "Financial aid",
        },
        Hebrew: {
            priceText: (
                <div className="price-text">
                    המחיר
                    {stage &&
                        <>
                        {' '}
                        <div style={{ display: 'inline-block', fontStyle: 'italic' }}> 
                            של {stage}
                        </div></>}:
                </div>
            ),
            yourPrice: "המחיר שלך",
            promoCode: "קוד מבצע",
            discount: "הנחה",
            financialAid: "בקשת סבסוד",
        }
    }
    const myStrings = myDict[course.language];
    return (
        <div className="composite-price">
            {false && mode === Mode.FEATURES_AND_PRICE &&
             !stage.includes("early") &&
             <div className="financial-aid-button-container">
                <div className="btn btn-purple financial-aid-button" onClick={onFinancialAid}>{myStrings.financialAid}</div>
             </div>}
            <div className="price-container">
                {myStrings.priceText}
                <div className={!token ? "price" : "original-price"}>
                    {priceStr(price, currency)}
                </div>
            </div>
            {token &&
                <>
                    <div className="price-text centered-content">
                        {myStrings.promoCode}: {token}<br />
                        {myStrings.discount}:{' '}
                        <div style={{ display: 'inline-block', fontWeight: 'bold' }}>
                            {discount}%
                        </div>
                    </div>
                    <div className="price-container">
                        <div className="price-text">
                            {myStrings.yourPrice}:
                        </div>
                        <div className="price">
                            {actualPriceStr(course)}
                        </div>
                    </div></>}
            {/*stage &&
                <>
                    <div className="projected-text">
                        (projected price upon release: {priceStr(projected, currency)})
                    </div>
            </>*/}
        </div>);
};

const RefundPolicyItem = ({item}) => {
    return (
        <div className="refund-policy-item">
            <div className="refund-policy-item-icon">
                <FontAwesomeIcon icon={faBalanceScale} />
            </div>
            <ReactMarkdown>
                {item}
            </ReactMarkdown>
        </div>
    );
}

const RefundPolicy = ({course, stage}) => {
    const myDict = {
        English: {
            refund: "Terms and Conditions",
        },
        Hebrew: {
            refund: "תנאים",
        }
    }
    const myStrings = myDict[course.language];
    const refundPolicy = (
        course.language === 'Hebrew' ? 
        REFUND_POLICY_HEBREW[stage] : REFUND_POLICY[stage])

    return (
        <>
        <div className="refund-policy">
            <div className="refund-policy-title">
            {myStrings.refund}{/*stage && ` (${stage})`*/}
            </div>
            {refundPolicy.map
                (item=><RefundPolicyItem key={item} item={item} />)}
        </div>
        </>
    );
}

const PricePolicyButtons = ({ course, mode, onPreview, onLearn, onPurchase, setSub, onAgree, onFinancialAid }) => {
    const myDict = {
        English: {
            purchase: "Purchase",
            riskFree: "risk-free",
            preview: "Preview the course",
            agree: "I agree with these terms",
        },
        Hebrew: {
            purchase: "לרכוש",
            riskFree: "ללא סיכון",
            preview: "לצפות בתצוגה מקדימה",
            agree: "אני מסכים לתנאים אלה",
        }
    }
    const myStrings = myDict[course.language];
    return (
        <div className="price-and-buttons">
            <Price
                course={course}
                mode={mode}
                price={course.price} currency={course.currency}
                /*stage={course.stage} projected={course.projected_price}*/
                stage="current" projected={course.projected_price}
                token={course.coupon ? course.coupon.token : ''}
                discount={course.coupon ? course.coupon.discount : 0} 
                onFinancialAid={onFinancialAid} />

            {mode === Mode.REFUND_POLICY &&
             <RefundPolicy course={course} stage={course.stage} />}
            
            <div className="card-button-container">
                {mode === Mode.FEATURES_AND_PRICE &&
                <>
                {!course.stage.includes("early") &&
                <div
                    className="btn btn-primary card-button-text"
                    onClick={() => onPreview()}>
                    {myStrings.preview}
                </div>}
                <div className="btn btn-green card-button-text"
                    onClick={() =>
                        onPurchase()}>
                    {myStrings.purchase}{!course.stage.includes("presale") && ` ${myStrings.riskFree}`}!
                </div>
                </>}

                {mode === Mode.REFUND_POLICY &&
                <div className="btn btn-green card-button-text"
                    onClick={() =>
                        onAgree()}>
                    {myStrings.agree}
                </div>}
                
                {mode === Mode.PAYPAL &&
                <Payment 
                    course={course} 
                    setLearnMode={onLearn} 
                    setSub={setSub} />}
            </div>
        </div>
    );
}

const Back = ({ course, backClass, heightStyle, onPreview, onLearn, onPurchase, setSub, onAgree, onFinancialAid, popMode, onClose, mode, dictionary, disableFlip }) => {
    return (
        <div className={backClass} style={heightStyle} id={"back"}>
            <div className="corner-button auth-form-close"
                onClick={() => onClose()} />
            {mode !== Mode.FEATURES_AND_PRICE &&
            <div className="corner-button auth-form-up"
                onClick={() => popMode()} />}

            {mode === Mode.FINANCIAL_AID &&
             <FinancialAid 
                course={course}
                disableFlip={disableFlip}
                onClose={onClose} />}

            {mode !== Mode.FINANCIAL_AID &&
            <>
            <div className="card-title-and-features">
                <div className="card-title-back">{translate(course.title_back, dictionary)[0]}</div>
                <Features features={course.features} dictionary={dictionary} />
            </div>
            
            <PricePolicyButtons
                course={course}
                mode={mode}
                onPreview={onPreview}
                onLearn={onLearn}
                onPurchase={onPurchase}
                setSub={setSub}
                onFinancialAid={onFinancialAid}
                onAgree={onAgree} />
            </>}
        </div>
    );
}

export default Back;