import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { BACKEND_CHANGE_PASSWORD} from '../../settings';
import { backendPostRequest } from '../util/BackendRequest';
import { InputElement, Button, Form } from '../util/Form';
import { checkInputs } from '../util/Checks';


const ChangePassword = ({user, closeMode, backMode}) => {
  const [password0, setPassword0] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  const onSubmit = e => {
    if (!checkInputs([
          "passwordInput0", "passwordInput1", "passwordInput2"]))
          return;
    const user = {
      old_password: password0,
      new_password1: password1,
      new_password2: password2
    };

    const myErrorParser = (res) => {
      if (res.old_password) return res.old_password[0];
      if (res.error) return res.error;
      return res.detail ? null : 'Some error occurred';
    }

    const myErrorHandler = () => {
      setPassword0('');
      setPassword1('');
      setPassword2('');
    }

    backendPostRequest(BACKEND_CHANGE_PASSWORD, 
                       true, JSON.stringify(user), 
                       myErrorParser, myErrorHandler)
      .then(data => {
        if (!data) return;
        toast.success("Password updated!");
        backMode();
      })
  }

  return (
        <Form
            title="Change password"
            close={true}
            closeMode={closeMode}
            back={true}
            backMode={backMode}
            inputs={[
                <InputElement key="passwordInput0"
                    id="passwordInput0" label="Old password:" type="password"
                    hook={password0} onChange={setPassword0} />,
                <InputElement key="passwordInput1"
                    id="passwordInput1" label="New password:" type="password"
                    hook={password1}
                    commonWords={[
                        user.firstname, 
                        user.email.substring(0, user.email.indexOf("@"))]}
                    onChange={setPassword1} />,
                <InputElement key="passwordInput2"
                    id="passwordInput2" label="Confirm password:" type="password"
                    hook={password2} onChange={setPassword2} />]}

            buttons={[
                <Button key="signupButton"  className="submit"
                    id="signupButton" icon={faDatabase} 
                    handler={onSubmit} />]}
        />
    );
};

export default ChangePassword;
