import React, { useState, useEffect } from 'react';
import { BACKEND_REGISTER_CONFIRM_EMAIL } from '../../settings';
import { backendPostRequest } from '../util/BackendRequest';
import { useParams, Redirect } from "react-router-dom";
import { toast } from 'react-toastify';
import { Mode } from './sub/Modes';

const SignupConfirmEmail = ({setGuestMode}) => {
    const [ok, setOk] = useState(false)
    const { token } = useParams();

    const myErrorParser = (res) => {
      if (res.detail && res.detail === 'ok') return null;
      if (res.error) return res.error;
      return 'Something went wrong';
    }

    useEffect(() => {
        backendPostRequest(BACKEND_REGISTER_CONFIRM_EMAIL, 
                           false, JSON.stringify({key: token}), 
                           myErrorParser)
        .then(data => {
          if (!data) return;
          toast.success('Thank you for confirming!');
          setGuestMode(Mode.LOGIN);
          setOk(true);
        });
    }, [token, setGuestMode]);

  if (ok) return <Redirect to='/' />
  return '';
};

export default SignupConfirmEmail;
