import React, { useEffect, useState } from 'react';

import { BACKEND_UNITS } from '../../settings';
import { backendGetRequest } from '../util/BackendRequest';
import { Tree, listToDict } from '../util/Tree';
import TOC from './sub/Learning/TOC';
import Content from './sub/Learning/Content';
import Feedback from './Feedback';
import { DoneStates, expandOnlyId, nextUnit, updateTreeDone } from './sub/Learning/util';

const Learning = ({preview, course, closeMode, backMode}) => {
  const [tree, setTree] = useState(null);
  const [expand, setExpand] = useState({});
  const [done, setDone] = useState(null);
  const [curId, setCurId] = useState(null);
  const [curProgress, setCurProgress] = useState(null);
  const [reload, setReload] = useState(0);

  const onReloadClick = () => {
    setReload(reload + 1)
  }

  useEffect(() => {
    console.log('Loading course contents')
    backendGetRequest(BACKEND_UNITS + course.id + '/')
      .then(raw => {
        if (!raw) return;
        const rawUnits = raw['units'];
        const [ids, data, rootId] = 
          listToDict(rawUnits, 'id', el=>el.type==='root');
        const tree = new Tree(
            ids, rootId,
            (id)=>{
              const node = data[id];
              const result =
                {'title': node['title'],
                  'type': node['type'],
                  'url': node['url'],
                  'content': node['content'],
                  'questions_url': node['questions_url'],
                  'attachments': JSON.parse(node['attachments']),
                  'for_preview': node['for_preview'],
                  'under_construction': node['under_construction']};
              if (result.questions_url) {
                const problemLink = [['coding rooms', 'The problem at Coding Rooms', result.questions_url]]
                result.attachments = problemLink.concat(result.attachments);
              }
              return result;
            },
            (id)=>{
              const node = data[id]; 
              return JSON.parse(node['children']);
            });
        setTree(tree);
        
        const expandCopy = {...expand};
        let newExpand = {};
        for (const el of rawUnits) {
          if (el.id in expandCopy)
            newExpand[el.id] = expandCopy[el.id]
          else
            newExpand[el.id] = (el.id === tree.rootId);
        }
        setExpand(newExpand);

        let done = {};
        for (const el of raw['done'])
          done[el.unit]=DoneStates.DONE;
        updateTreeDone(tree, done, setDone);

        if (!curId) { // Not during reload
          // Restore saved position
          const rawCurrent = raw['current'];
          if (rawCurrent.length) {
            const id = rawCurrent[0].unit;
            if (!preview || tree.nodes[id].data.for_preview) {
              setCurId(id);
              setCurProgress(rawCurrent[0].progress);
              expandOnlyId(tree, id, expand, setExpand);
              return;
            }
          }

          // Make the first enabled unit active
          nextUnit(preview, tree, tree.rootId, setCurId, expand, setExpand);
          setCurProgress(0);
        }
      });
      // I am not really using expand
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course.id, preview, reload]);

  if (!tree || !expand || !done ||
      // Cannot check !curProgress, since can be 0. 
      curId === null || curProgress === null) return '';
  return (
    <>
      <TOC 
        preview={preview}
        course={course}
        tree={tree} 
        expand={expand} 
        setExpand={setExpand}
        done={done}
        setDone={setDone}
        curId={curId} 
        setCurId={setCurId}
        setCurProgress={setCurProgress}
        closeMode={closeMode}
        backMode={backMode} 
        onReloadClick = {onReloadClick} />
      <Content
        preview={preview}
        courseId={course.id}
        tree={tree} 
        curId={curId} 
        setCurId={setCurId} 
        curProgress={curProgress}
        setCurProgress={setCurProgress}
        expand={expand}
        setExpand={setExpand}
        done={done}
        setDone={setDone} />
      <Feedback 
        questions={true}
        questions_url={tree.nodes[curId].data.questions_url}
        className="learning" />
    </>
  );
}

export default Learning;
