import React from 'react';
import { backgroundImageSrc } from './CourseCard/util';
import { Mode } from './Modes';

const SmallCard = ({course, setCourse, setMode}) => {
  const onClick = e => {
    setCourse(course);
    setMode(course.sub ? Mode.LEARNING : Mode.COURSE_CARD);
  }
      
  return (
    <div className = {"small-card" + (course.language==='Hebrew' ? " rtl" : '')} onClick={() => onClick()}>        
      {course.image_url &&
      <div className="card-image" 
            style={{backgroundImage: backgroundImageSrc(course.image_url)}}>
        <div 
          className="image-attribution"
          dangerouslySetInnerHTML={{__html: course.image_attribution}}>
        </div>
      </div>
      }

      <div className="card-title-subtitle">
      <div className="card-title">{course.title} </div>
      <div className="card-subtitle">{course.subtitle}</div>
      </div>
    </div>
  );
}

export default SmallCard;
