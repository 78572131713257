import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { findDOMNode } from 'react-dom'

import { BACKEND_CONTENT } from '../../../../settings';
import { backendGetRequest } from '../../../util/BackendRequest';
import { Center, CenterBelowLogo } from '../../../util/Rendering';
import { DoneStates, nextUnit, saveProgress, updateContentDone } from './util';
import { toast } from 'react-toastify';

const Player = ({preview, courseId, tree, 
                 curId, url, setCurId, 
                 curProgress, setCurProgress, 
                 expand, setExpand,
                 done, setDone}) => {
  const [origProgressUsed, setOrigProgressUsed] = useState(false)
  const [fullscreenMode, setFullscreenMode] = useState(false)

  let player = null;
  const ref = (p) => {player = p;}

  // This allows to avoid saving progress on each change
  // of curProgress.
  // https://stackoverflow.com/a/69694476/2725810
  const curProgressRef = useRef();

  useEffect(() => {
    curProgressRef.current = curProgress; // <-- cache state value on change
  }, [curProgress]);

  useEffect(()=>{
    return ()=>{
      saveProgress(courseId, curId, curProgressRef.current);
    };
  }, [courseId, curId]) 

  const onStart = () => {
    if (fullscreenMode)
      findDOMNode(player).requestFullscreen().catch(
        (err) => {toast.error("Could not activate full-screen mode :(")}
      );
    if (!origProgressUsed) {
      player.seekTo(curProgress, 'fraction');
      setOrigProgressUsed(true);
    }
    else {
      setCurProgress(0);
    }
  }
  const onEnded = () => {
    const node = tree.nodes[curId]
    const unit = node.data;
    setFullscreenMode(document.fullscreenElement !== null);
    if (unit.questions_url) {
      toast.info('Please remember to mark the problem done once completed.');
    }
    else {
      updateContentDone(
        courseId, tree, node, DoneStates.DONE, done, setDone);
    }
    nextUnit(preview, tree, curId, setCurId, expand, setExpand);
    setCurProgress(0);
  }
  const onPause = (data) => {
    saveProgress(courseId, curId, curProgress);
  }

  const onProgress = (data) => {
    if (origProgressUsed) // Can be triggered before onStart.
      setCurProgress(data.played);
  }

  return (
    <ReactPlayer
      className="react-player"
      ref={ref}
      url={url}
      controls={true}
      playing={true}
      onStart={onStart}
      onEnded={onEnded}
      onPause={onPause}
      onProgress={onProgress}
      progressInterval={1000} />);
}

const Content = ({preview, courseId, tree, 
                  curId, setCurId, 
                  curProgress, setCurProgress, 
                  expand, setExpand,
                  done, setDone}) => {
  const [url, setUrl] = useState(null);
  const [/*content*/, setContent] = useState(null);
  useEffect(() => {
    if (!curId) return '';
    const data = tree.nodes[curId].data;
    if (data.for_preview) {
      setUrl(data.url); 
      setContent(data.content);
      return;
    }
    const myErrorHandler = (myError) => {
      if (myError.includes("not own"))
          window.location.reload();
    }
    backendGetRequest(BACKEND_CONTENT + curId + '/', null, myErrorHandler)
      .then(data => {
        if (!data) return;
        setUrl(data['url']);
        setContent(data['content']);
      })
  }, [curId, tree.nodes]);

  if (!curId || !url) return '';
  return (
    <div className = "learning-content-container">
      <div className = "learning-content">
        <CenterBelowLogo>
          <div className="learning-player-container">
            <Player 
              preview={preview}
              courseId={courseId}
              tree={tree} 
              curId={curId}
              url={url}
              setCurId={setCurId}
              curProgress={curProgress} 
              setCurProgress={setCurProgress}
              expand={expand}
              setExpand={setExpand}
              done={done}
              setDone={setDone} />
          </div>
        </CenterBelowLogo>
      </div>
    </div>
  );
}

export default Content;