import React from 'react';
import { Form, Tab } from '../../../util/Form';
import { Mode } from './Modes';
import { Mode as DashboardMode} from '../Modes';
import { isValid } from './util';

const Course = ({course, setDashboardCourse, setDashboardMode}) => {
  const onCourse = () => {
    setDashboardMode(DashboardMode.COURSE_CARD);
    setDashboardCourse(course);
  }
  return (
    <div className="code-course code-field-value" onClick={onCourse}>
      {course.title}
    </div>
  );
}

const Code = ({dashboardMode, courses, code, setDashboardCourse, setDashboardMode}) => {
  const couponMode = (dashboardMode === DashboardMode.COUPONS);
  const keyMode = !couponMode;

  const course_ids = code.courses.map(el => el['id']);
  courses = courses.filter(el => course_ids.includes(el['id']) );
  
  let valid = true;
  let [expiryDate, expiryTime]  = [null, null];
  if (couponMode) { // unneeded for access keys
    const arr = code.expiry.split(' ').filter(i => i);
    expiryDate = `${arr[0]} ${arr[1]} ${arr[2]}, ${arr[4]}`;
    expiryTime = arr[3];
    valid = isValid(code);
  }

  let codeTitleRowClass = "code-title-row";
  let codeTokenClass = "code-token";
  let codeFieldClass = "code-field";
  let codeFieldNameClass = "code-field-name";
  let codeFieldValueClass = "code-field-value";
  let codeTitle = "Code";
  if (keyMode) {
    codeTokenClass += " key-token";
    codeFieldClass += " key-field";
    codeFieldNameClass += " key-field-name";
    codeFieldValueClass += " key-field-value";
    codeTitleRowClass += " key-title-row";
    codeTitle += ':';
  }

  return (
    <div className="code">
      <div className={codeTitleRowClass}>
        <div className={codeTokenClass}>
          <div className={codeFieldClass}>
            <div className={codeFieldNameClass}>{codeTitle}</div>
            <div className={codeFieldValueClass}>{code.token}</div>
          </div>
        </div>
        {couponMode &&
        <div className="code-discount-expiry">
          <div className="code-field">
            <div className="code-field-name">Discount</div>
            <div className="code-field-value">{code.discount}%</div>
          </div>
          <div className="code-field">
            <div className="code-field-name">Expiry (UTC)</div>
            <div className="code-field-value" 
                 {...!valid && {style:{color:'red'}}}>
              {expiryDate}<br />{expiryTime}
            </div>
          </div>
        </div>}
      </div>
      {valid &&
      <div className="code-courses">
        <div className="code-field-name" style={{marginBottom: '0.5rem'}}>
          Courses: 
        </div>
        {courses.map(
              course => <Course 
                          course={course} 
                          key={course.id} 
                          setDashboardCourse={setDashboardCourse}
                          setDashboardMode={setDashboardMode} />)}
      </div>}
    </div>
  );
}

const Codes = ({dashboardMode, courses, codes, 
                  setDashboardCourse, setDashboardMode}) => {
  const codeComponents = (codes) => {
    return codes.map(
      code => <Code dashboardMode={dashboardMode}
                        courses={courses} 
                        code={code} key={code.token} 
                        setDashboardCourse={setDashboardCourse}
                        setDashboardMode={setDashboardMode} />)
  }
  const couponMode = (dashboardMode === DashboardMode.COUPONS);
  const codeType = couponMode ? "coupon" : "access";
  
  if (!codes.length) return (
    <div className="code-field-name">
      You do not have any {codeType} codes.<br />Go to the "Add" tab to add one.
    </div>
  );
  return (
    <div className="codes">
      {codeComponents(codes.filter(el => isValid(el)))}
      {codeComponents(codes.filter(el => !isValid(el)))}
    </div>
  );
}

const View = ({dashboardMode, courses, codes, setMode, closeMode, backMode, 
              setDashboardCourse, setDashboardMode}) => {
  const onAdd = () => setMode(Mode.ADD)
  return (
    <Form
      title={dashboardMode === DashboardMode.COUPONS ? 
             "Promo codes" : "Access codes"}
      close={true}
      closeMode={closeMode}
      back={true}
      backMode={backMode}
      tabs={[
          <Tab key="View"
              label="View" 
              rightMargin="margin-right-s" />,
          <Tab key="Add"
              label="Add"
              onClick={onAdd}
              rightMargin="margin-right-s" />,
            ]}
      inputs={<Codes dashboardMode={dashboardMode}
                       courses={courses} 
                       codes={codes} 
                       setDashboardCourse={setDashboardCourse}
                       setDashboardMode={setDashboardMode} />}
    />
  );
}

export default View;