import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    cell: {
        borderRightColor: borderColor,
        borderRightWidth: 1,
        fontSize: 15
    },
    item: {width: '35%'},
    unit: {width: '14%'},
    qty: {width: '9%'},
    sub: {width: '14%'},
    tax: {width: '14%'},
    total: {width: '14%'},
  });

  const InvoiceTableHeader = () => (
    <View style={styles.container}>
        <Text style={[styles.cell, styles.item]}>Item</Text>
        <Text style={[styles.cell, styles.unit]}>Unit Cost</Text>
        <Text style={[styles.cell, styles.qty]}>Qty</Text>
        <Text style={[styles.cell, styles.sub]}>Subtotal</Text>
        <Text style={[styles.cell, styles.tax]}>Tax</Text>
        <Text style={[styles.cell, styles.total]}>Total</Text>
    </View>
  );
  
  export default InvoiceTableHeader