import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { BACKEND_RESET_PASSWORD_CONFIRM} from '../../settings';
import { backendPostRequest } from '../util/BackendRequest';
import { InputElement, Button, Form } from '../util/Form';
import { checkInputs } from '../util/Checks';
import { Mode } from './sub/Modes';
import { useParams, Redirect } from 'react-router-dom';
import { CenterBelowLogo } from '../util/Rendering';
import Logo from '../dashboard/sub/Logo';


const LoginResetPassword = ({setGuestMode}) => {
  const [ok, setOk] = useState(false)
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const { firstname, email, uid, token } = useParams();

  const onSubmit = e => {
    if (!checkInputs([
          "passwordInput1", "passwordInput2"])) return;
    const user = {
      uid: uid,
      token: token,
      new_password1: password1,
      new_password2: password2
    };

    const myErrorParser = (res) => {
      if (res.error) return res.error;
      return res.detail ? null : 'Some error occurred';
    }

    const myErrorHandler = () => {
      setPassword1('');
      setPassword2('');
    }

    backendPostRequest(BACKEND_RESET_PASSWORD_CONFIRM, 
                       false, JSON.stringify(user), 
                       myErrorParser, myErrorHandler)
      .then(data => {
        if (!data) return;
        toast.success("Password successfully reset!");
        setGuestMode(Mode.LOGIN);
        setOk(true);
      })
  }

  if (ok) return <Redirect to='/' />

  return (
        <>
        <div className="dashboard-nav">
            <Logo className="logo-corner" />
        </div>
        <CenterBelowLogo>
        <Form
            title="Password reset"
            inputs={[
                <InputElement key="passwordInput1"
                    id="passwordInput1" label="New password:" type="password"
                    hook={password1}
                    commonWords={[firstname, email]}
                    onChange={setPassword1} />,
                <InputElement key="passwordInput2"
                    id="passwordInput2" label="Confirm password:" type="password"
                    hook={password2} onChange={setPassword2} />]}

            buttons={[
                <Button key="signupButton" className="submit"
                    id="signupButton" icon={faDatabase} 
                    handler={onSubmit} />]}
        />
        </CenterBelowLogo>
        </>
    );
};

export default LoginResetPassword;
