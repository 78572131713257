import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import Logo from "./sub/Logo";
import { BACKEND_COURSES, BACKEND_USER} from '../../settings';
import { backendGetRequest } from '../util/BackendRequest';
import { CenterBelowLogo } from '../util/Rendering';

import { Mode } from './sub/Modes';
import Dropdown from './sub/Dropdown';

import SmallCards from './SmallCards';
import CourseCard from './CourseCard';
import Learning from './Learning';
import Logout from '../guest/Logout'
import Feedback from './Feedback';
import Codes from './Codes';
import { isValid } from './sub/Codes/util';
import ChangePassword from './ChangePassword';
import { listToDict } from '../util/Tree';
import Receipts from './Receipts';

const Dashboard = ({setGuestMode}) => {
    const [user, setUser] = useState(null)
    const [courses, setCourses] = useState(null);
    const [coupons, setCoupons] = useState(null);
    const [keys, setKeys] = useState(null);
    const [dictionary, setDictionary] = useState(null);
    const [course, setCourse] = useState(null);
    const [courseId, setCourseId] = useState(0); // avoid infinite useEffect
    const [mode, setMode] = useState(Mode.SMALL_CARDS);
    const [modeStack, setModeStack] = useState([]);

    useEffect(() => {
        const userErrorParser = (res) => {
            return !(res.detail) ? null : 'The session has expired.';
        }

        const userErrorHandler = (res) => {
            localStorage.clear();
        }

        backendGetRequest(BACKEND_USER, userErrorParser, userErrorHandler)
        .then(user => {
            if (!user) return;
            setUser(user);
            backendGetRequest(BACKEND_COURSES)
            .then(data => {
                if (!data) return;
                //console.log(data['courses'])
                setCourses(data['courses']);
                setCoupons(data['coupons']);
                setKeys(data['keys']);

                const rawDict = 
                    listToDict(data['dictionary'], 'key')
                let [keys, dictionary] = [rawDict[0], rawDict[1]];
                for (const key of keys) {
                    dictionary[key] = JSON.parse(dictionary[key].value);
                }
                setDictionary(dictionary)
            });
        });
    }, []);

    useEffect(() => {
        setCourseId(course ? course.id : 0);
    }, [course]);

    // Update current course based on updated coupons.
    useEffect(() => {
        const computeCoupon = () => {
            let coupon = null;
            let discount = 0;
            // Only coupons that are valid and relevant to the course.
            const myCoupons = coupons.filter(
                (coupon) => {
                    if (!isValid(coupon)) return false;
                    const course_ids = coupon.courses.map(el => el['id']);
                    return course_ids.includes(courseId);
                });
            for (const c of myCoupons) {
                if (c.discount > discount) {
                    coupon = c;
                    discount = coupon.discount;
                }
            }
            return coupon;
        }
        if (courseId)
            setCourse(c => {return {...c, coupon: computeCoupon()}});
    }, [coupons, courseId]);

    const pushMode = (newMode) => {
        setModeStack(s => [...s, mode]);
        setMode(newMode);
    }

    const popMode = () => {
        const lastIndex = modeStack.length - 1;
        setMode(modeStack[lastIndex]);
        const newModeStack = [...modeStack];
        newModeStack.splice(lastIndex, 1);
        setModeStack(newModeStack);
    }

    const closeMode = () => {
        setMode(Mode.SMALL_CARDS);
        setModeStack([]);
    }

    const onPreview = () => {
        pushMode(Mode.PREVIEW);
    }

    const onLearn = (course=null) => {
        if (course) setCourse(course);
        setMode(Mode.LEARNING);
    }

    const setSub = (course) => {
        const coursesCopy = [...courses];
        const index = coursesCopy.findIndex(
            (el)=>{return el.id===course.id});
        coursesCopy[index].sub = true;
        setCourses(coursesCopy);
    }

    const getContent = () => {
        switch (mode) {
            case Mode.SMALL_CARDS:
                return (
                <SmallCards
                    courses={courses}
                    setCourse={setCourse}
                    setMode={setMode} />);
            case Mode.COURSE_CARD:
                return (
                <CourseCard 
                    course={course}
                    dictionary={dictionary}
                    setCourse={setCourse}
                    closeMode={closeMode}
                    onPreview={onPreview}
                    onLearn={onLearn}
                    setSub={setSub} />);
            case Mode.PREVIEW:
                return (
                <Learning
                    preview={true}
                    course={course}
                    closeMode={closeMode}
                    backMode={popMode} />);
            case Mode.LEARNING:
                return (
                <Learning
                    preview={false}
                    course={course}
                    closeMode={closeMode}
                    backMode={popMode} />);
            
            case Mode.COUPONS:
            case Mode.KEYS:
                return (
                <Codes
                    dashboardMode={mode}
                    courses={courses}
                    codes={mode===Mode.COUPONS ? coupons : keys}
                    procRes={data => {
                             if (mode===Mode.COUPONS) 
                                setCoupons(data);
                             else {
                                setCourses(data['courses']);
                                setCoupons(data['coupons']);
                                setKeys(data['keys']);
                             }}}
                    closeMode={closeMode}
                    backMode={popMode}
                    setDashboardCourse={setCourse}
                    setDashboardMode={setMode} />);
            case Mode.RECEIPTS:
                return (
                <Receipts
                    courses={courses}
                    onLearn={onLearn}
                    closeMode={closeMode}
                    backMode={popMode} />)
            case Mode.CHANGE_PASSWORD:
                return (
                <ChangePassword
                    user={user}
                    closeMode={closeMode}
                    backMode={popMode} />);
            default:
                return '';
        }
    }

    if (!localStorage.getItem('token') || mode === Mode.LOGOUT) 
        return <Logout setGuestMode={setGuestMode} />;

    if (!courses) return '';

    const learnOrPreviewMode = 
        (mode === Mode.LEARNING || mode === Mode.PREVIEW);
    const authFormMode = (
        mode === Mode.COUPONS ||
        mode === Mode.KEYS ||
        mode === Mode.RECEIPTS ||
        mode === Mode.CHANGE_PASSWORD
        );

    return (
        <>
        <div className="dashboard-nav">
            <Logo className="logo-corner" />
            <div className="dashboard-nav-user">
                {user.first_name}, I'm proud<br/>that you are here!
            </div>
            <Dropdown 
                setMode={setMode}
                pushMode={pushMode} />
        </div>
        {!authFormMode && !learnOrPreviewMode &&
        <Feedback 
            questions={false} 
            className="" /> }
        {learnOrPreviewMode && getContent()}
        {!learnOrPreviewMode &&
        <CenterBelowLogo>
            {getContent()}
        </CenterBelowLogo>
        }
        <div className="image-attribution global-padding">
            Image attributions appear <a href="https://github.com/DrMeir-co/attributions">here</a>
        </div>
        </>
    );
};

export default Dashboard;
