import React from 'react';
import { Redirect } from 'react-router-dom';

import { Mode } from './sub/Modes';

import Welcome from './Welcome';
import Login from './Login';
import Signup from './Signup';
import { FRONTEND_DASHBOARD } from '../../settings';

const Guest = ({mode, setMode}) => {
    const getContent = () => {
        switch (mode) {
            case Mode.WELCOME: return <Welcome setMode={setMode} />
            case Mode.LOGIN: return <Login setMode={setMode} />
            case Mode.SIGNUP: return <Signup setMode={setMode} />
            default: return '';
        }
    }

    if (localStorage.getItem('token') !== null)
        return <Redirect to={FRONTEND_DASHBOARD} />;

    return getContent();
};

export default Guest;
