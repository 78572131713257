import React from 'react';
import { toast } from 'react-toastify';


// TODO: Consider SunEditor
// http://suneditor.com/sample/html/examples.html
// https://www.npmjs.com/package/suneditor-react

const onFeedback = () => {
  navigator.clipboard.writeText("feedback@drmeir.co");
  toast.success(
    <div>
      Please drop me a line at <a href="mailto:feedback@drmeir.co">feedback@drmeir.co</a>! <br />
      (copied and ready to paste)</div>)
}

const onQuestion = (questions_url) => {
  const address = "question@drmeir.co";
  navigator.clipboard.writeText(address);
  toast.success(
    <div>
      {questions_url &&
       <div>
        If your question is not answered <a href={questions_url}>here</a>, <br />
       </div>
      }
      {questions_url?'p':'P'}lease send {questions_url?'it':'your question'} to <a href={`mailto:${address}`}>{address}</a>!<br />
      (copied and ready to paste)</div>)
}

const Feedback = (props) => {
  return (
    <div className={`feedback-container ${props.className}`}>
      <div className="feedback" onClick={onFeedback}>Feedback</div>
      {props.questions &&
      <div className="feedback question" 
           onClick={() => onQuestion(props.questions_url)}>Question?</div>}
    </div>
  );
}

export default Feedback;
