// https://stackoverflow.com/a/43467144/2725810
function isValidHttpUrl(string) {
  let url;
  
  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export const iconSrc = (raw) => {
  if (isValidHttpUrl(raw)) return raw;
  return '/icons/' + raw + '.svg';
}

export const backgroundImageSrc = (raw) => {
  if (isValidHttpUrl(raw)) 
    return `url(${raw})`;
  return `url(/images/${raw}`;
}

export const priceStr = (price, currency) => {
  return (currency === 'ILS' ? '₪' : '$') + parseFloat(price).toFixed(2);
}

export const actualPrice = (course) => {
  const discount = course.coupon ? course.coupon.discount : 0;
  return (course.price * (100 - discount) / 100).toFixed(2)
}

export const actualPriceStr = (course) => {
  return priceStr(actualPrice(course), course.currency);
}