import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { toast } from 'react-toastify';
import { BACKEND_ORDERS_CAPTURE, BACKEND_ORDERS_CREATE } from "../../../settings";
import { backendPostRequest } from "../../util/BackendRequest";

/*
https://developer.paypal.com/developer/applications

Visa for testing:
https://developer.paypal.com/developer/creditCardGenerator/
4032039600664058
1122
165

To generate a secure URL, use localtunnel.
https://localtunnel.github.io/www/

Examples:
https://developer.paypal.com/demo/checkout/
*/

// https://paypal.github.io/react-paypal-js/?path=/docs/example-paypalbuttons--default
const ButtonWrapper = ({course, setLearnMode, setSub}) => {
    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    const onCancel = () => {
        toast.error("You cancelled the payment.")
    }
    const onError = (err) => {
        toast.error("An error occurred when processing your payment.")
    }

    // https://developer.paypal.com/docs/platforms/checkout/set-up-payments/#1-add-payment-buttons
    // Note: the error I am seeing is OK.
    // https://stackoverflow.com/a/55747156/2725810
    const onCreate= (data, actions) => {
        return backendPostRequest(
            BACKEND_ORDERS_CREATE + course.id + '/' +
            (course.coupon ? course.coupon.token + '/' : ''));
    }

    const onApprove= (data, actions) => {
        //console.log("data", data);
        return backendPostRequest(
            BACKEND_ORDERS_CAPTURE + data.orderID + '/')
        .then(res => {
            if (!res) return;
            //console.log("Response", res);
            setSub(course);
            toast.success('Payment completed. Thank you!')
            setLearnMode()
            return res;
        });
    }

    return (
        <>
            { isPending && <div className="spinner" /> }
            <PayPalButtons 
                className="paypal"
                style={{
                layout: 'vertical',
                //color:  'yellow',
                shape:  'pill',
                //label:  'pay',
                tagline: false,
                height: 35}} 
                createOrder={(data, actions) => 
                             {return onCreate(data, actions);}}
                onCancel={() => onCancel()}
                onError={(err) => onError()}
                onApprove={(data, actions) => onApprove(data, actions)}
            />
        </>
    );
};

const Payment = ({course, setLearnMode, setSub}) => {
    return (
        <>
        <PayPalScriptProvider
            options={{ 
                "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                "vault": true, // https://github.com/paypal/paypal-checkout-components/issues/1467
                "currency": course.currency, // https://www.paypal-community.com/t5/REST-APIs/Javascript-SDK-currency-code-error/td-p/1762788
                //"container width": 400, // https://paypal.github.io/react-paypal-js/?path=/docs/example-paypalbuttons--default
             }}>
            <ButtonWrapper
                course={course}
                setLearnMode={setLearnMode}
                setSub={setSub} />
        </PayPalScriptProvider>
        </>
    );
};

export default Payment;
