import React, { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    outerContainer: {
        marginTop: 24,
        width: '55%'
    },
    innerContainer: {
        marginTop: 4.5,
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: 18,
        //lineHeight: 27
    },
    label: {
        fontWeight: 'heavy',
    }
  });


    const InvoiceNo = ({order}) => {
        const arr = order.captured.split(' ')
        //console.log(order.captured);
        const date = `${arr[1]} ${arr[2]}, ${arr[4]}   ${arr[3]}`
        return (
            <Fragment>
                <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
                <View style={styles.outerContainer}>
                    <View style={styles.innerContainer}>
                        <Text style={styles.label}>Invoice No:</Text>
                        <Text>{order.invoice_id}</Text>
                    </View >
                    <View style={styles.innerContainer}>
                        <Text style={styles.label}>Date:</Text>
                        <Text>{date}</Text>
                    </View >
                </View>
                </View>
            </Fragment>);
    }
  
  export default InvoiceNo