import React from 'react';
import { toast } from 'react-toastify';
import { Form } from '../../../util/Form';

const Course = ({course, onLearn, setCourse, setLearnMode}) => {
  const onCourse = () => {onLearn(course);}
  const onRefund = () => {
    const mailto = `feedback+refund_${course.id}@drmeir.co`
    const address = ` feedback+refund_${course.id}@<wbr/>drmeir.co`
    navigator.clipboard.writeText(address);
    toast.success(
      <div>
        Just drop me a line at 
        <a href={`mailto:${mailto}`}>
          <div style={{display: 'inline'}} dangerouslySetInnerHTML={{__html: address}} />
        </a>! <br />
        It's in the clipboard for your conveniene.<br />
        I will greatly appreciate if you tell me what you feel about the course.
      </div>, {'autoClose': 20000})
  }
  const onReceipt = () => {setCourse(course);}

  return (
    <div className="receipts-course-container">
      <div className="receipts-course" onClick={onCourse}>
        {course.title}
      </div>
      <div style={{display:'flex'}}>
        <div className="btn btn-green card-button-text" style={{marginRight: '1em'}} onClick={onReceipt}>Receipt</div>
        <div className="btn btn-purple card-button-text" onClick={onRefund}>Refund</div>
      </div>
    </div>
  );
}

const Courses = ({courses, onLearn, setCourse}) => {
  if (!courses.length) return (
    <div className="code-field-name">
      When you purchase a course,<br/>your receipt will appear here!
    </div>
  );
  return (
    <div className="receipts-courses">
      {courses.map(course => 
        <Course
            course={course}
            onLearn={onLearn}
            key={course.title} 
            setCourse={setCourse} />)}
    </div>
  );
}

const All = ({courses, onLearn, setCourse, closeMode, backMode}) => {
  return (
    <Form
      title="Receipts & Refunds"
      close={true}
      closeMode={closeMode}
      back={true}
      backMode={backMode}
      inputs={
        <Courses
            courses={courses}
            onLearn={onLearn} 
            setCourse={setCourse} />}
    />
  );
}

export default All;