import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    outerContainer: {
        marginTop: 0
    },
    innerContainer: {
        marginTop: 48
    },
    billTo: {
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique'
    },
  });


const BillTo = ({order}) => {
    const payer = order.capture_response.payer;
    const name = payer.name;
    return (
    <>
    <View style={styles.outerContainer}>
        <View style={styles.innerContainer}>
            <Text style={styles.billTo}>Bill To:</Text>
            <Text>{name.given_name} {name.surname}</Text>
        </View>
        <View style={styles.innerContainer}>
            <Text style={styles.billTo}>Payment method:</Text>
            <Text>PayPal {payer.email_address}</Text>
        </View>
    </View>
    </>);
}

export default BillTo