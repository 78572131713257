import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { toast } from 'react-toastify';
import { BACKEND_FINANCIAL_AID, FINANCIAL_AID, FINANCIAL_AID_HEBREW,  INPUT_TYPES } from '../../../../settings';
import { actualPrice, priceStr } from './util';
import { backendPostRequest } from "../../../util/BackendRequest";

const FinancialAid = ({course, disableFlip, onClose}) => {
  const [ans0, setAns0] = useState('');
  const [ans1, setAns1] = useState('');
  const [ans2, setAns2] = useState(actualPrice(course));

  const financialAid = (
    course.language === 'Hebrew' ? FINANCIAL_AID_HEBREW : FINANCIAL_AID);

  const onSubmit = () => {
    backendPostRequest(
      BACKEND_FINANCIAL_AID + course.id + '/', true,
      JSON.stringify({'ans0': ans0, 'ans1': ans1, 'ans2': ans2})
    ).then(res => {
      if (res)
        toast.success(
          financialAid.FINANCIAL_AID_SUBMITTED, {'autoClose': 20000})
      onClose();
    })
  }

  return (
    <div className="financial-aid">
      <div className="financial-aid-title-intro">
        <div className="financial-aid-title">
          {financialAid.FINANCIAL_AID_TITLE}
        </div>
        <div className="financial-aid-intro">
          {financialAid.FINANCIAL_AID_INTRO.map(
            p => 
            <div className="financial-aid-intro-sentence" key={p}>
              <ReactMarkdown>
              {p}
              </ReactMarkdown>
            </div>)}
        </div>
      </div>

      <div className="financial-aid-questions">
        {financialAid.FINANCIAL_AID_QUESTIONS.map(
          (q, i) => {
            const question = q[0];
            const inputType = q[1];
            let input = null;
            const [hook, setter] = {
              0: [ans0, setAns0], 1: [ans1, setAns1], 2: [ans2, setAns2]}[i];
            switch (inputType) {
              case INPUT_TYPES.TEXTAREA:
                input = <textarea 
                  className="financial-aid-question-i" 
                  rows="4" 
                  onClick={()=>{disableFlip();}}
                  value={hook}
                  onChange={e => setter(e.target.value)} />
                break;
              case INPUT_TYPES.SLIDER:
                input = <div style={{textAlign:'end'}}>
                <input 
                  className="financial-aid-question-i"
                  type="range" 
                  min="0" 
                  max={course.price}
                  step={course.price/20}
                  onClick={()=>{disableFlip();}}
                  value={hook}
                  onChange={e => {disableFlip(); setter(e.target.value)}} />
                {priceStr(hook, course.currency)}
                </div>
                break;
              default: break;
            }
            return (
              <div key={question} className="financial-aid-question-container">
                <ReactMarkdown className="financial-aid-question-q">
                {question}
                </ReactMarkdown>
                {input}
              </div>
            );
          })}
      </div>
      
      <div className="financial-aid-only-once">
        {financialAid.FINANCIAL_AID_ONLY_ONCE}
      </div>
      <div className="financial-aid-submit-button-container">
        <div 
          className="btn btn-purple financial-aid-submit-button" 
          onClick={onSubmit}>
          {financialAid.FINANCIAL_AID_SUBMIT}
        </div>
      </div>
    </div>
  );
}

export default FinancialAid;
