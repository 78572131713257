import React, { useState, useEffect } from 'react';
import View from './sub/Codes/View';
import Add from './sub/Codes/Add';
import { Mode } from './sub/Codes/Modes';

const Codes = ({dashboardMode, courses, codes, procRes, closeMode, backMode,
                 setDashboardCourse, setDashboardMode}) => {
    const [mode, setMode] = useState(Mode.VIEW);

    useEffect(() => { // swithched between keys and codes
        setMode(Mode.VIEW);
    }, [dashboardMode]);

    const getContent = () => {
        switch (mode) {
            case Mode.ADD:
                return (
                    <Add
                        dashboardMode={dashboardMode}
                        codes={codes}
                        procRes={procRes}
                        setMode={setMode}
                        closeMode={closeMode}
                        backMode={backMode}
                    />
                );
            case Mode.VIEW:
                return (
                    <View
                        dashboardMode={dashboardMode}
                        courses={courses}
                        codes={codes}
                        setMode={setMode}
                        closeMode={closeMode}
                        backMode={backMode}
                        setDashboardCourse={setDashboardCourse}
                        setDashboardMode={setDashboardMode}
                    />
                );
            default:
                return '';
        }
    }
    return getContent();
};

export default Codes;
