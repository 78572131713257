export const Mode = {
    SMALL_CARDS: 'SMALL_CARDS',
    COURSE_CARD: 'COURSE_CARD',
    LEARNING: 'LEARNING',
    PREVIEW: 'PREVIEW',

    COUPONS: 'COUPONS',
    KEYS: 'KEYS',
    RECEIPTS: 'RECEIPTS',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    LOGOUT: 'LOGOUT',
}