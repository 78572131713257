import React, { useState } from 'react';
import { toast } from 'react-toastify';
//import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';

import { Form, Tab, InputElement, Button } from '../../../util/Form';
import { Mode } from './Modes';
import { Mode as DashboardMode} from '../Modes';
import { BACKEND_COUPONS_ADD, BACKEND_KEYS_ADD } from '../../../../settings';
import { backendPostRequest } from '../../../util/BackendRequest';
import { checkInputs } from '../../../util/Checks';

const Add = ({dashboardMode, codes, procRes, setMode, closeMode, backMode}) => {
  const [token, setToken] = useState('');
  const onView = () => setMode(Mode.VIEW)

  const couponMode = (dashboardMode === DashboardMode.COUPONS);
  const keyMode = !couponMode;
  let codeType = "coupon";
  let capitalCodeType = "Coupon";
  let url = BACKEND_COUPONS_ADD;
  if (keyMode) {
    codeType = "access";
    capitalCodeType = "Access";
    url = BACKEND_KEYS_ADD;
  }
  const onAddButton = () => {
    setToken("");
    document.activeElement.blur(); // remove focus from the button
    if (!checkInputs(["tokenInput"])) return;
    if (codes.map(el=>el['token']).includes(token)) {
      toast.error(`You already have this ${codeType} code!`);
      return;
    }
    backendPostRequest(url + token + '/')
      .then(data => {
        if (!data) return;
        procRes(data);
        toast.success(
          <div>
            {capitalCodeType} code added!<br />
            {couponMode &&
            <>
            <div style={{marginTop: '0.5rem'}}/>
            During purchase, the best promo code will apply!
            </>}
          </div>)
      })
  }

  return (
    <Form
      title={`${capitalCodeType} codes`}
      close={true}
      closeMode={closeMode}
      back={true}
      backMode={backMode}
      tabs={[
        <Tab key="View"
            label="View"
            onClick={onView} 
            rightMargin="margin-right-s" />,
        <Tab key="Add"
            label="Add"
            rightMargin="margin-right-s" />,
        ]}
      inputs={[
        <InputElement key="tokenInput"
                      id="tokenInput" label={`Enter ${codeType} code:`} type="text"
                      hook={token} onChange={setToken} />]}
      buttons={[
        <Button key="addButton" className="submit"
            id="addButton" icon={faDatabase} 
            handler={onAddButton} />]}
    />
  );
}

export default Add;
