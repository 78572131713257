import { ERROR_PASSWORDS_NOT_MATCHING, ERROR_PASSWORD_LENGTH } from "../../settings";

const isPasswordValid = (password) => {
    if (password.length < 6) return false;
    return true;
}

export const clearCustomErrors = (ids) => {
    for (const id of ids)
        document.getElementById(id).setCustomValidity("");
}

// Display error message for the first input that is not valid and return false.
// Return true if all inputs are valid
export const checkInputs = (ids) => {
    for (const id of ids) {
        const inpObj = document.getElementById(id);
        inpObj.setCustomValidity(""); // Get rid of a previously set error.
        // console.log(id, inpObj.checkValidity(), inpObj.validationMessage);
        if (id === "passwordInput1" && !isPasswordValid(inpObj.value))
            inpObj.setCustomValidity(ERROR_PASSWORD_LENGTH);
        if (id === "passwordInput2") {
            const password1 = document.getElementById("passwordInput1").value;
            if (password1 !== inpObj.value) 
                inpObj.setCustomValidity(ERROR_PASSWORDS_NOT_MATCHING);
        }
        if (!inpObj.checkValidity()) {
            inpObj.reportValidity();
            return false;
        }
    }
    return true;
}