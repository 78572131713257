class Node {
    id;
    data;
    children;
    parentId;
    depth; // Depth in depth-first traversal
    next; // Next node in depth-first traversal
    prev; // Previous node in depth-first traversal

    constructor(id, data, children) {
        this.id = id;
        this.data = data;
        this.children = children;
    }

    dump(indent) {
        console.log(indent + "ID:", this.id);
        console.log(indent + "Data:", this.data);
        console.log(indent + "Children:", this.children);
        console.log(indent + "Parent:", this.parentId);
        console.log(indent + "Depth:", this.depth);
        console.log(indent + "Next:", this.next);
        console.log(indent + "Prev:", this.prev);
    }
}

export class Tree {
    nodes = {}; // dictionary from id to node
    rootId; // id of the root
    maxDepth = 0; // maximal depth of a node
    dfOrder = []; // array of ids in the order of depth-first traversal

    // Perform depth traversal starting at this.nodes[id].
    // At each node, calle:
    //    preAction before expansion, passing it current node.
    // If output is true, return JSX for the tree.
    // expandCondition can be used to avoid expanding nodes.
    depthFirst = 
        (id, 
         preAction=()=>{}, postAction=()=>{}, expandCondition=()=>true) => {
        const df = (id, depth, parentId) => {
            // console.log(id)
            let node = this.nodes[id];
            // so can pass only node to actions
            node.parentId = parentId;
            node.depth = depth;

            const preActionRes = preAction(node);
            const expandRes = 
                expandCondition(id) &&
                node.children.map(childId => df(childId, depth + 1, id))
            return postAction(node, preActionRes, expandRes);
        }
        return df(id, 0, -1);
    }

    // dataFunc - function returning data based on id
    // childrenFunc - function returning ids of children based on id
    constructor(ids, rootId, dataFunc, childrenFunc) {
        const initAction = (node) => {
            const id = node.id
            if (node.depth > this.maxDepth) this.maxDepth = node.depth;
            const i = this.dfOrder.push(id) - 1; // index of id in dfOrder
            if (i > 0) {
                const prevId = this.dfOrder[i-1];
                this.nodes[prevId].next = id;
                node.prev = prevId;
            }
        }
        this.rootId = rootId;
        for (const id of ids) 
            this.nodes[id] = new Node(id, dataFunc(id), childrenFunc(id));
        this.depthFirst(rootId, initAction);
    }

    dump() {
        console.log(`Tree rooted at ${this.rootId}`);
        console.log(`Maximal depth: ${this.maxDepth}`)
        console.log(`Order of DF traveral: ${this.dfOrder}`)
        for (const id of Object.keys(this.nodes)) {
            console.log(`Node ${id}:`);
            this.nodes[id].dump('    '); 
        }
    }

    // Next id in the specified direction satisfying condition.
    // direction is either 1 or -1.
    // TODO: make it private when supported. https://stackoverflow.com/questions/53675785/how-do-i-enable-eslint-classprivatemethods-parser-plugin
    seek(id, condFunc, direction) {
        let index = this.dfOrder.indexOf(id);
        while (true) {
            index += direction;
            if (index >= this.dfOrder.length) break;
            id = this.dfOrder[index];
            if (condFunc(this.nodes[id].data)) return id;
        }
        return null;
    }

    // Next id satisfying condition.
    next(id, condFunc) {return this.seek(id, condFunc, 1)};

    // Previous id satisfying condition.
    prev(id, condFunc) {return this.seek(id, condFunc, -1)};

    // Get path from root to id, inclusively.
    path(id) {
        let res = [id];
        while (id !== this.rootId) { 
            id=this.nodes[id].parentId;
            res.push(id);
        }
        return res.reverse();
    }

    // Get array of nodes based on array of ids.
    idsToNodes(ids) {
        let res = [];
        for (const id of ids) res.push(this.nodes[id]);
        return res;
    }

    // Perform action on each id from ids. 
    // The action is passed id and the node.
    actOnList(ids, action) {
        for (const id of ids) action(id, this.nodes[id]);
    }

    // Perform action on nodes not in ids. 
    // The action is passed id and data of the node.
    actOnNotList(ids, action) {
        const notList = this.dfOrder.filter(el=>!ids.includes(el));
        this.actOnList(notList, action);
    }
}

// Translate a list of dictionaries 
// with each dictionary having the keyField key, 
// to dictionary with the values of keyField as keys 
// and elements of the original list as values.
// rootFunc (if supplied) determines whether a given element of the 
// list is the root.
// Returns the list of keys, the dictionary and (if rootFunc is supplied,
// the id of the root.
export const listToDict = (data, keyField='id', rootFunc=null) => {
    let keys = [];
    let dict = {};
    let rootId = null;
    for (const el of data) {
        const key = el[keyField];
        keys.push(key);
        dict[key] = el;
        if (rootFunc && rootFunc(el)) rootId = key;
    }
    return rootFunc?[keys, dict, rootId]:[keys, dict];
}