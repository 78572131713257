import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 72
    },
    reportTitle:{
        fontSize: 18,
        textTransform: 'uppercase',
    }
  });


  const InvoiceThankYouMsg = () => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>Thank you for choosing DrMeir!</Text>
    </View>
  );
  
  export default InvoiceThankYouMsg