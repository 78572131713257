import React, { useState, useEffect } from 'react';
import { BACKEND_LOGOUT } from '../../settings';
import { backendPostRequest } from '../util/BackendRequest';
import { Redirect } from 'react-router-dom';
import { Mode } from './sub/Modes';

const Logout = ({setGuestMode}) => {
    const [ok, setOk] = useState(false);

    useEffect(() => {
        backendPostRequest(BACKEND_LOGOUT)
        .then(_ => {
            localStorage.clear(); 
            setGuestMode(Mode.WELCOME)
            setOk(true);
        });
    }, [setGuestMode]);

    if (ok) return <Redirect to='/' />;

    return '';
};

export default Logout;
