import React, { Fragment, memo } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderPlus, faFolderMinus, faFileVideo, faPaperclip, faTools, faSignature } from '@fortawesome/free-solid-svg-icons';
import {faYoutube, faAmazon, faStackOverflow} from '@fortawesome/free-brands-svg-icons'
import {faFilePowerpoint, faFileWord} from '@fortawesome/free-regular-svg-icons';
import { DoneStates, toggleContentDone } from './util';

// memo is needed to avoid re-render after each call to setCurProgress. 
// https://stackoverflow.com/a/60386619/2725810
const TOC = memo(({preview, course, tree, expand, setExpand, done, setDone,
              curId, setCurId, setCurProgress, closeMode, backMode, onReloadClick}) => {
  const onUnitClick = (id) => {
    const unit = tree.nodes[id].data;
    if (unit.type !== 'section') { // content
      if (preview && !unit.for_preview) {
        toast.error('This unit is not for preview.');
        return;
      }
      if (unit.under_construction) {
        toast.error('This unit is under construction.');
        return;
      }
      setCurId(id);
      setCurProgress(0);
      return;
    }
    let expandCopy = {...expand};
    if (expandCopy[id]) // on collapse, collapse the whole subtree.
      tree.depthFirst(id, (node)=>expandCopy[node.id]=false);
    else
      expandCopy[id] = true;
    setExpand(expandCopy);
  }

  const onDoneClick = (id) => {
    const node = tree.nodes[id]
    const unit = node.data;
    console.log(id);

    if (unit.questions_url && !(node.id in done)) {
      toast.warn('By checking this box, you certify that you have completed this assignment problem.');
    }
    if (unit.type === 'section') {
      toast.info("Section's done status is computed automatically."); 
      return;
    }
    if (preview && !unit.for_preview) {
      toast.error('This unit is not for preview.');
      return;
    }
    if (unit.under_construction) {
      toast.error('This unit is under construction.');
      return;
    }
    toggleContentDone(course.id, tree, node, done, setDone);
  }

  const AttachmentDropdownItem = ({attachment}) => {
    // https://stackoverflow.com/a/49917066/2725810
    const onDownloadAttachment = (e, url) => {
      e.stopPropagation(); // No change to unit behind the dropdown menu

      const a = document.createElement('a')
      a.href = url
      a.target = "_blank"
      a.rel = "noopener noreferrer"
      a.download = url.split('/').pop() // the name of the downloaded file
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)

      /*
      // For links opened in new tab, the following results in not opening the dropdown of attachments on hover following clicking the link.
      const dropdownItemsElement = e.currentTarget.parentNode;
      dropdownItemsElement.style.display='none';
      */
    }

    let icon = null;
    let iconColor = null;
    let symbol = null;
    let symbolColor = null;
    switch (attachment[0]) {
      case 'powerpoint': 
        icon = faFilePowerpoint;
        iconColor = "brown";
        break;
      case 'word':
        icon = faFileWord;
        iconColor = "blue";
        break;
      case 'youtube': 
        icon = faYoutube;
        iconColor = "red";
        break;
      case 'amazon':
        icon = faAmazon;
        iconColor = "orange";
        break;
      case 'stackoverflow':
        icon = faStackOverflow;
        iconColor = "orange";
        break;
      case 'coding rooms':
        symbol = '◇';
        symbolColor = "blue";
        break;
      default: break;
    }
    return (
      <div 
        className="learning-unit-attachment"
        onClick={(e)=>onDownloadAttachment(e, attachment[2])}>
        {icon && <FontAwesomeIcon 
          style={{color: iconColor, marginInlineEnd:'0.5em'}} 
                  icon={icon} />}
        {symbol && <div style={{color: symbolColor, fontSize: '1.5em',
                                marginInlineEnd:'0.5em'}}>
                  {symbol}</div>}
        <div className="learning-unit-attachment-link">
          {attachment[1]}
        </div>
      </div>
    );
  }
  const AttachmentDropdown = ({attachments}) => {
    return (
      <div className="learning-unit-attachment-dropdown-items">
        <div className="learning-unit-attachment-dropdown-items-content">
          {attachments.map(
            a => <AttachmentDropdownItem key={a[1]} attachment={a}/>)}
        </div>
      </div>
    );
  }

  const Attachment = ({unit}) => {
    const onMouseOver = (e) => {
      const dropdownItemsElement = e.currentTarget.children[1];
      dropdownItemsElement.style.display = 'block';
    }

    const onMouseOut = (e) => {
      const dropdownItemsElement = e.currentTarget.children[1];
      dropdownItemsElement.style.display = 'none';
    }

    if (!unit.attachments.length) return '';
    return (
      <div 
        className="learning-unit-attachment-dropdown"
        onMouseOver={(e)=>onMouseOver(e)}
        onMouseOut={(e)=>onMouseOut(e)}
        >
        <FontAwesomeIcon 
          style={{color: 'green', marginInlineEnd:'0.5em'}} 
                  icon={faPaperclip} />
        <AttachmentDropdown attachments={unit.attachments} />
      </div>
      );
  }

  const renderUnit = (node) => {
    const id = node.id;
    if (id === tree.rootId) return '';

    const unit = node.data;
    const fontSize = 2+(tree.maxDepth-node.depth)/10;
    const marginInlineStart = (node.depth-1)*4;
    let marginTop = 0.5 * fontSize;

    // If it's the first unit, there is already margin-top for parent element
    if (tree.nodes[tree.rootId].children[0] === id)
      marginTop = 0;
    const [icon, color] = {
      'section': 
        (expand[id] ?
          [faFolderMinus, 'lightblue'] : [faFolderPlus, 'blue']),
      'video': 
        [unit.under_construction ?
          faTools: (unit.questions_url ? faSignature : faFileVideo),
         !unit.under_construction &&
         (!preview || unit.for_preview) ? 'green' : 'gray']
    }[unit.type];
    let extraRightMargin = 0
    if (icon === faFileVideo) extraRightMargin = 0.3;
    if (icon === faSignature) extraRightMargin = -0.26;
    return (
      <>
      <div style={{marginInlineStart: marginInlineStart/fontSize + 'em',
                   fontSize: fontSize + 'em',
                   marginTop: marginTop/fontSize + 'em'}}
                   className="learning-unit">
        <div className="learning-unit-done-container"
             onClick={()=>onDoneClick(id)}>
          {done[id]===DoneStates.DONE && 
           <div className="learning-unit-done">✔</div>}
          {done[id]===DoneStates.PARTIAL && 
           <div className="learning-unit-partial">·</div>}
        </div>
        <div className="learning-unit-icon-title" 
          onClick={()=>onUnitClick(id)}>
          <Attachment unit={unit} />
          <FontAwesomeIcon className="learning-unit-icon"
            style={{color: color, marginInlineEnd:(1+extraRightMargin)+'em'}} icon={icon} />
          <div 
            style={{
              backgroundColor: id===curId?'lightgray':'inherit',
              color: (!preview || unit.for_preview || 
                      unit.type === 'section') ? 
                'black' : 'gray'}}
            className="learning-unit-title">
            {unit.title}
          </div>
        </div>
      </div>
      </>
    );
  }

  const outputPostAction = (node, preActionRes, expandRes) => (
    <Fragment key={node.id}>
    {preActionRes}
    {expandRes}
    </Fragment>
  );

  return (
    <div className = 
      {`learning-toc-container 
        ${course.language === 'Hebrew' ? 'rtl' : ''}`}>
      <div className = "learning-toc">
        <div 
          className="corner-button auth-form-close" 
          onClick={closeMode} />
        {preview &&
         <div 
          className="corner-button auth-form-up" 
          onClick={backMode} />
        }
        <div className = "learning-title">
          {course.title}
        </div>
        <div className = "learning-subtitle">
        {course.subtitle + ' '}
          {preview &&
           (course.language === 'Hebrew' ?
            ' (תצוגה מוקדמת)' : '(Preview) ')}
          <div className = "learning-reload" onClick={() => onReloadClick()} />
        </div>
        <div className="learning-toc-items">   
        {tree.depthFirst
          (tree.rootId, renderUnit, outputPostAction, (id)=>expand[id])}
        </div>
      </div>
    </div>
  );
});

export default TOC;
