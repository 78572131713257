import { BACKEND_CURRENT_UPDATE, BACKEND_DONE_UPDATE } from "../../../../settings";
import { backendPostRequest } from "../../../util/BackendRequest";

export const DoneStates = {PARTIAL: 0, DONE: 1};

// Expand only the path to id and collapse everything else.
export const expandOnlyId = (tree, id, expand, setExpand) => {
    const expandCopy = {...expand};
    const path = tree.path(id);
    tree.actOnList(path, (id)=>{expandCopy[id]=true;})
    tree.actOnNotList(path, (id)=>{expandCopy[id]=false;})
    setExpand(expandCopy);
}

export const nextUnit = (preview, tree, curId, setCurId, expand, setExpand) => {
    while (true) {
        curId = tree.next(curId, unit=>(unit.type !== 'section'));
        if (!curId) break;
        if (!preview || tree.nodes[curId].data.for_preview) break;
    }
    if (curId) setCurId(curId);
    if (setExpand)
        expandOnlyId(tree, curId, expand, setExpand);
    return curId;
}


const updateSectionDone = (node, done, setDone=null) => {
    if (node.data.type !== 'section') return;
    const doneCopy = (setDone ? {...done} : done);
    let doneFlag = true;
    let nothingFlag = true; // section not started
    for (const id of node.children) {
        if (id in doneCopy) 
            nothingFlag = false;
        if (!(id in doneCopy) || doneCopy[id] !== DoneStates.DONE)
            doneFlag = false;
    }
    if (doneFlag) 
        doneCopy[node.id] = DoneStates.DONE;
    else if (nothingFlag)
        delete doneCopy[node.id];
    else 
        doneCopy[node.id] = DoneStates.PARTIAL;
    if (setDone) setDone(doneCopy);
}

export const updateParentsDone = (tree, node, done, setDone=null) => {
    const doneCopy = (setDone ? {...done} : done);
    let ids = tree.path(node.parentId).reverse();
    for (const node of tree.idsToNodes(ids))
        updateSectionDone(node, doneCopy);
    if (setDone) setDone(doneCopy);
}

export const updateTreeDone = (tree, done, setDone=null) => {
    const doneCopy = (setDone ? {...done} : done);
    tree.depthFirst(
        tree.rootId, ()=>{}, 
        (node)=>{updateSectionDone(node, doneCopy)});
    if (setDone) setDone(doneCopy);
}

export const updateContentDone = 
    (courseId, tree, node, doneFlag, done, setDone=null) => {
    const doneCopy = (setDone ? {...done} : done);
    if (doneFlag)
        doneCopy[node.id] = DoneStates.DONE;
    else
        delete doneCopy[node.id];
    updateParentsDone(tree, node, doneCopy);
    if (setDone) setDone(doneCopy);
    backendPostRequest(BACKEND_DONE_UPDATE + courseId + '/' + node.id + '/' + (doneFlag ? '1' : '0') + '/');
}

export const toggleContentDone = 
    (courseId, tree, node, done, setDone=null) => {
    const doneCopy = (setDone ? {...done} : done);
    updateContentDone(courseId, tree, node, !(node.id in doneCopy), doneCopy)
    if (setDone) setDone(doneCopy);
}

export const saveProgress = (courseId, curId, curProgress) => {
    backendPostRequest(BACKEND_CURRENT_UPDATE + courseId + '/' + curId + '/' + curProgress + '/');
}