import { useState, useCallback } from 'react'

export function useForceUpdate() {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

export const CenterBelowLogo = (props) => (
    <div className="center-box-flex below-logo">
        <div className="auto-margin">
            {props.children}
        </div>
    </div>
);

export const Center = (props) => (
    <div className="center-box-flex justify-content">
        {props.children} 
    </div>
);

export const CONFIRMATION_EMAIL_SENT = 'CONFIRMATION_EMAIL_SENT'
export const keyToList = (data, key, liClass = "") => {
    return (
        (data[key]) &&
        <div>
            {data[key].map(el => (<li className={liClass} key={el}>{el}</li>))}
        </div>
    )
}
