import React, { useEffect, useState } from 'react';
import { Document, Page, StyleSheet, PDFViewer, Image, Font, Text} from '@react-pdf/renderer';
import Rubik from './Rubik-Regular.ttf';


import { backendGetRequest } from '../../../util/BackendRequest';
import { BACKEND_ORDERS_GET } from '../../../../settings';
import logo from './logo.jpg'
import InvoiceTitle from './InvoiceTitle';
import InvoiceNo from './InvoiceNo';
import BillTo from './BillTo';
import InvoiceItemsTable from './InvoiceItemsTable';
import InvoiceThankYouMsg from './InvoiceThankYouMsg';

// https://react-pdf.org/components
// Based on the example: https://github.com/kagundajm/react-pdf-tables

// Create styles
Font.register({
    family: 'Rubik',
    format: "truetype",
    src: Rubik,
    fontStyle: "normal",
	  fontWeight: "normal",
});


const styles = StyleSheet.create({
  page: {
    border: 'none',
    padding: 36,
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  logo: {
        width: '220rem',
        height: '46rem',
        fontFamily: 'Rubik',
    },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

// Create Document Component
const MyDocument = ({course, order}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image style={styles.logo} src={logo} />
      <InvoiceTitle title='Invoice / Tax Receipt'/>
      <InvoiceNo order={order}/>
      <BillTo order={order}/>
      <InvoiceItemsTable course={course} order={order} />
      <InvoiceThankYouMsg />
    </Page>
  </Document>
);

const Receipt = ({course, closeMode, backMode}) => {
  const [order, setOrder] = useState(null);
  useEffect(() => {
    backendGetRequest(BACKEND_ORDERS_GET + course.id + '/')
      .then(order => {
        if (!order) return;
        order.capture_response = JSON.parse(order.capture_response);
        //console.log(order);
        setOrder(order);
      })
    }, [course.id]);

  if (!order) return '';
  return (
    <div className="receipt-container">
      <div style={{position: 'relative', height:'inherit', width:'inherit'}}>
        <div 
          className="corner-button auth-form-close" 
          onClick={closeMode} />
        <div 
          className="corner-button auth-form-up" 
          onClick={backMode} />
        <PDFViewer style={{
          height:'inherit', 
          width:'inherit', 
          paddingTop: '3em', 
          border:'none'}}>
          <MyDocument course={course} order={order} />
        </PDFViewer>
      </div>
    </div>
  );
}

export default Receipt;