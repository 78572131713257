import { BACKEND_URL, ERROR_NO_SERVER } from "../../settings"
import { toast } from 'react-toastify';

const standardErrorParser = (res) => {
    return res['error'] ? res['error'] : null;
}

const backendRequest = (
    route, type, auth, body, errorParser, errorHandler) => {
    return (
        fetch(BACKEND_URL + route, {
            method: type,
            headers: {
            'Content-Type': 'application/json',
            ...(auth) && {Authorization: `Token ${localStorage.getItem('token')}`}
            },
            ...(body) && {body: body}
        })
        .catch(_ => { toast.error(ERROR_NO_SERVER); return null; }) 
        .then(res => {
            if (!res) return null; // does this ever happen?
            return res.json();
        })
        // Parsing JSON failed, so it's probably a tuple indicating an SMTP error or something of the kind.
        .catch(_ => { toast.error('An internal server error occurred. If the error persists, please submit a bug report.'); return null; })
        .then(res => {
            if (!res) return null; // does this ever happen?
            if (res.detail === 'Invalid token.') {
                const reloadFlag = localStorage.length;
                localStorage.clear();
                // Reload will result in rendering <Logout> by <Dashboard>.
                if (reloadFlag) window.location.reload();
            }
            const myError = 
                errorParser ? errorParser(res) : standardErrorParser(res);
            if (myError) {
                toast.error(myError);
                if (errorHandler) errorHandler(myError);
                return null;
            }
            return res;
        })
    )
}

export const backendGetRequest = (
    route, errorParser = null, errorHandler = null) => {
    return backendRequest(
        route, 'GET', true, false, errorParser, errorHandler);
}

export const backendPostRequest = (
    route, auth = true, body = false, 
    errorParser = null, errorHandler = null) => {
    return backendRequest(
        route, 'POST', auth, body, errorParser, errorHandler)
}