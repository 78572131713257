import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';

export const Tab = ({label, onClick = null, rightMargin = ""}) => {
    if (onClick)
        return (
            <div onClick={onClick} 
                 className={"tab tab-inactive " + rightMargin}>
                {label}
            </div>);
    return (
        <div className={"tab tab-active " + rightMargin}>
            {label}
        </div>)       
}

const placeholder = (type, label, id) => {
    if (type === 'text') {
        if (label.includes('name')) return 'e.g. John';
        if (label.includes('coupon')) return '';
    }
    if (type === 'email') return 'e.g. john@gmail.com';
    if (id === 'passwordInput1') return 'your password (min 6 characters)';
    if (id === 'passwordInput2') return 'your password';
    return '';
}

export const InputElement = 
    ({id, label, type, hook, onChange, commonWords=[]}) => (
    <>
    <label className="auth-label" htmlFor={id}>{label}</label>
    <input
        id={id}
        className="auth-input auth-input-margin-bottom"
        name={type} // Needed for autocompletion to work!
        type={type}
        value={hook}
        placeholder={placeholder(type, label, id)}
        required
        onChange={e => onChange(e.target.value)}
    />
    {id === "passwordInput1" && 
     <>
     {/*<div id="suggestion">some</div>*/}
     {/* https://www.npmjs.com/package/react-password-strength-bar */}
     <div className="negative-margin-top-m margin-bottom-s" />
     <PasswordStrengthBar 
        password={hook}
        minLength="6"
        scoreWords={['very weak', 'weak', 'okay', 'good', 'strong']}
        userInputs={["drmeir"].concat(commonWords)}
        scoreWordClassName="score-word"        
        onChangeScore={
            (_, feedback)=> {
                return;
                // eslint-disable-next-line
                try {
                    const suggestion = feedback.suggestions[0];
                    console.log(feedback);
                    document.getElementById("suggestion").innerHTML = 
                        suggestion ? suggestion : "";
                }
                catch {document.getElementById("suggestion").innerHTML = "";}
            } }/>
     <div className="margin-bottom-m" />
     </>}
    </>
);

export const Button = ({id, className, icon, handler}) => {
    const primaryFlag = (typeof icon !== 'string')
    return (
        <button
            id={id}
            className= {`${primaryFlag ? "auth-button" : "auth-button-float"} ${className}`}
            onClick={() => handler()}>
            {primaryFlag ? <FontAwesomeIcon icon={icon} /> : icon}
        </button>
    )
}

export const Form = ({title, close, closeMode, back, backMode, tabs, inputs, buttons}) => {
    useEffect(() => {
        const form = document.getElementsByClassName('auth-form')[0];
        try {
            const button = document.getElementsByClassName('submit')[0];
            form.addEventListener("keyup", (event) => {
                if (event.key === 'Enter')
                    button.click();
            });
        }
        finally {}
    }, []);
    return (
    <div className="auth-form">
        <div className={title? "auth-form-title-tabs" : ""}>
            {title && 
            <div className="auth-form-title">
                {title}
            </div>}
            <div style={{paddingRight:'4em'}} className="right-content font-indie-flower">
                {tabs}
            </div>
        </div>
        {/* The container is for the scrolling to work
            https://stackoverflow.com/a/70142840/2725810 */}
        <div className="auth-form-frame-container">
            <div className="auth-form-frame">
                <div className="auth-form-corner-buttons">
                    {close && 
                    <div 
                        className="corner-button auth-form-close" 
                        onClick={closeMode} />}
                    {back && 
                    <div 
                        className="corner-button auth-form-up" 
                        onClick={backMode} />}
                </div>
                <div className="auth-form-body">
                    <div className="centered-content">
                        {inputs}
                        <div className="auth-buttons">
                            {buttons}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}