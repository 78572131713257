import 'react-toastify/dist/ReactToastify.css';
import "./main.scss" // after ReactToastify.css to override styles

import {HashRouter, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Guest from './views/guest/Guest';
import LoginResetPassword from './views/guest/LoginResetPassword';
import SignupConfirmEmail from './views/guest/SignupConfirmEmail';
import Dashboard from './views/dashboard/Dashboard';
import { FRONTEND_DASHBOARD, FRONTEND_LOGIN, FRONTEND_SIGNUP } from './settings';
import { useState } from "react";
import { Mode as GuestMode } from "./views/guest/sub/Modes";

const App = () => {
    const [guestMode, setGuestMode] = useState(GuestMode.WELCOME)
    return (
        <>
        <ToastContainer className="toast-container"
            position="top-center"
            limit={1}
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        <div className='App'>
            {/* The first solution in:
                https://stackoverflow.com/questions/27928372/react-router-urls-dont-work-when-refreshing-or-writing-manually */}
            <HashRouter>
            <Switch>
                <Route 
                    path="/" 
                    render={()=>
                        <Guest 
                            mode={guestMode} setMode={setGuestMode}/>} 
                    exact />
                <Route 
                    path={FRONTEND_DASHBOARD} 
                    render={()=>
                        <Dashboard 
                            setGuestMode={setGuestMode}/>} exact />
                <Route 
                    path={FRONTEND_SIGNUP + '/confirm-email/:token'} 
                    render={()=>
                        <SignupConfirmEmail 
                            setGuestMode={setGuestMode}/>} exact />
                <Route 
                    path={FRONTEND_LOGIN + '/reset-password/:firstname/:email/:uid/:token'} 
                    render={()=>
                        <LoginResetPassword 
                            setGuestMode={setGuestMode}/>} exact />
            </Switch>
            </HashRouter>
        </div>
        </>
    );
};

export default App;
