import React, { useState } from 'react';
import All from './sub/Receipts/All';
import Receipt from './sub/Receipts/Receipt';

const Receipts = ({courses, onLearn, closeMode, backMode}) => {
    const [course, setCourse] = useState(null)
    const myBackMode = () => {setCourse(null);}
    if (course) return (
        <Receipt 
            course={course} 
            closeMode={closeMode}
            backMode={myBackMode} />);
    return (
        <All
            courses={courses.filter(course=>course.sub)}
            onLearn={onLearn}
            setCourse={setCourse}
            closeMode={closeMode}
            backMode={backMode} />);
}

export default Receipts;