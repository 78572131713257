import React from "react";

import Logo from "../dashboard/sub/Logo";
import { Center } from "../util/Rendering";
import { Mode } from './sub/Modes';

// https://codesandbox.io/s/react-bootstrap-submenu-forked-y4nt2
const Welcome = ({setMode}) => {
    const onLogin = () => {
        setMode(Mode.LOGIN);
    }
    const onSignup = () => {
        setMode(Mode.SIGNUP);
    }
    return (
        <>
        <div className="welcome">
            <Center>
                <div style={{display:'inline-block'}}>
                <Logo className="margin-bottom-l" animations />
                <div className="centered-content font-indie-flower animation-fade-in-4s">
                    <div className="btn btn-primary"
                         onClick={onLogin}>Login</div>
                    <span className="bigger-or"> OR </span>
                    <div className="btn btn-green"
                         onClick={onSignup}>Sign Up</div>
                </div>
                </div>
            </Center>
        </div>
        <div className="image-attribution global-padding">
            Photo by <a href="https://unsplash.com/@nickmorrison?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Nick Morrison</a> on <a href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
        </div>
        </>
    );
}

export default Welcome;