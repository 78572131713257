import ReactMarkdown from 'react-markdown';
import ReactPlayer from 'react-player/lazy'

const Face = ({course, faceClass, heightStyle, onClose}) => {
  return (
    <div className={faceClass} style={heightStyle} id={"face"}>
      <div className= "corner-button auth-form-close" 
            onClick={() => onClose()} />
      <div className="card-title-subtitle">
      <div className="card-title">{course.title} </div>
      <div className="card-subtitle">{course.subtitle}</div>
      </div>

      {course.description &&
      <ReactMarkdown 
        className="card-description" 
        children={course.description} />
      }

      <div style={{width:'100%', height:'20em'}}>
      <ReactPlayer 
        url={course.intro_url} 
        controls={true}
        width='100%' 
        height='100%' />
      </div>

      <div className="card-proceed">
        {course.language !== 'Hebrew' ?
          'Click anywhere outside the video to proceed' : 'לחץ במקום כלשהו מחוץ לסרטון כדי להמשיך'}
      </div>
    </div>
  );
}

export default Face;